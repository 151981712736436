import React from 'react'
import './style.css'
import image from '../../assets/testImages/download.jpeg'

export default function eventsModal(props) { 
    return (
        <div className='eventsModalContentContainer'>
            <div className='eventsModalPoster'>
                <div onClick={props.close} style={{fontSize: '20px'}}>&#10006;</div>
                <img src={props.event[0].ePoster} alt="events-poster" />
            </div>
            <div>
                <h2 className='eventModalTitle'>{props.event[0].eName}</h2>
                <div className='eventsTimeDateContainer'>
                    <span className='eventModalTimeContainer'>{props.event[0].eDate}</span>
                    <span className='eventModalDateContainer'>{props.event[0].eTime}</span>
                </div>
                <p className='eventModalDescription'>{props.event[0].eLocation}</p>
                <p className='eventModalDescription'>{props.event[0].eDescription}</p>
            </div>
        </div>
    )
}