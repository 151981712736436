import Footer from '../../layouts/Footer';
import './account.css';
import EditBtn from '../../assets/Edit.png'
import Add from '../../assets/AddProduct.png'
import logoutIcon from '../../assets/checkOut.png'
import verify from '../../assets/verification.png'
import VerificationModal from './VerificationModal';
import cross from '../../assets/cross.png'
import React, { useState, useEffect, useContext } from "react"
import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import axios from 'axios';
// import { getAllCategories } from '../../services/category';
// import { uploadImages } from '../../services/images';
import { deleteProductById, productByUser } from '../../services/product';
import swal from "sweetalert"
import Male from '../../assets/man.png';
import Female from '../../assets/woman.png';
import User from '../../assets/user.png';
import AddProduct from './AddProduct';
import { logout } from '../../services/auth';
import locationIcon from '../../assets/location.png'
import exit from '../../assets/exit.png'
import { editUserData } from '../../services/users';
import AvatarSelector from '../../layouts/LoginSignup/AvatarSelector';

const baseUrl = process.env.REACT_APP_API_URL
export default function Account() {
  const { currentUser } = useContext(AuthContext)
  console.log("The data of user",currentUser);
  // editing user data
  const [userNameEdit, setUserNameEdit] = useState(currentUser.name);
  const editName = (e) => {
    setEditData({ ...editData, userName: e.target.value }); setUserNameEdit(e.target.value);
  }
  const [userMobileEdit, setUserMobileEdit] = useState(currentUser.phoneNumber);
  const editMobile = (e) => {
    setEditData({ ...editData, mobile: e.target.value });
    setUserMobileEdit(e.target.value);
  }
  const [userEmailEdit, setUserEmailEdit] = useState(currentUser.email);
  const editEmail = (e) => {
    setEditData({ ...editData, email: e.target.value });
    setUserEmailEdit(e.target.value);
  }
  // const [loading,setLoading]= useState(false)
  // const [categories, setCategories] = useState([]);
  const [userProd, setUsProd] = useState([])
  // const [data, setData] = useState({ name: "", desc: "", category: "", price: "", mrp: "", file: null });
  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [avatarConfig, setAvatarConfig] = useState(false);
  const [verified, setVerified] = useState('');
  const [firstname, setFirstname] = useState('');
  const [collegeName, setCollegeName] = useState('');
  // const [preview, setPreview] = useState(false);
  useEffect(() => {
    let a = async () => {
      let res2 = await productByUser();
      console.log(res2.Products)
      setUsProd(res2.Products)
    }
    a();
    let Name = currentUser?.name;
    let fName = Name?.split(' ')[0];
    setFirstname(fName)
    setCollegeName(currentUser.collegeName);
    let v = currentUser.verified;
    console.log(v);
    setVerified(v);
  }, [])
  const [user, setUser] = useState();
  const unsub = async () => {
    if (currentUser) {
      setTimeout(() => {
        setUser(true);
      }, 100)
    }
  };
  unsub();
  // onAuthStateChanged(auth,(result) => {
  //   if(result){
  //     setTimeout(() => {
  //       setUser(true)
  //     },100)
  //   }
  // })
  // const closeModal = function () {
  //   setModalOpen(false);
  // }
  const openModal = function (e) {
    e.preventDefault();
    setModalOpen(true);
  }
  const editModalOpen = () => {
    setEditModal(true);
  }
  const editAvatarOpen = () => {
    setAvatarConfig(!avatarConfig);
  }
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({ userName: currentUser.name, mobile: currentUser.phoneNumber, email: currentUser.email })
  const handeleditClick = () => {
    edit?setEdit(false):setEdit(true);
  }
  const handelEditDone = async (e) => {
    let editDataRes = await editUserData(currentUser._id,editData.userName,editData.mobile,editData.email,null,null);
    // console.log("Response",editDataRes)
    if(editDataRes.success) {
      swal({text : editDataRes.success});
    } else {
      swal({text : "An unknown error occured!", icon : "warning"});
    }
    // setEditData(
    //   {
    //     userName: editData.userName,
    //     mobile: editData.mobile,
    //     email: editData.email
    //   }
    //   );
    //   console.log(editData);
  }
  const sweetAlert = (item_id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          handleSubmit(item_id)
          window.location.reload(true);
        }
      });
  }
  const handleSubmit = async (id) => {
    const c = await deleteProductById(id);
    console.log(c)
    if (c.success) {
      swal({ text: "Deleted Product Successfully" })
    } else if (c.error) {
      swal({ text: "Deleted Product Failed, try again later", icon: "error" })
    }
  }
  const [verifyModal, setVerifyModal] = useState(false)
  const verifyModalHandler = () => {
    setVerifyModal(true);
    setModalOpen(false);
  }
  const [gender, setGender] = useState(Male);
  const Gender = "Male";
  const genderChange = () => {
    if (Gender === "Male") {
      setGender(Male);
    }
    else if (Gender === "Female") {
      setGender(Female);
    }
    else {
      setGender(User);
    }
  }
  if (currentUser) {
    if(avatarConfig){
      return (
        <AvatarSelector />
      )
    }
    return (

      <div className='mainAccountDiv'>
        <div className='account-anim'>
          <div className='editAddProductModal'>
            {/* Edit Modal Open */}
            {editModal && <AddProduct closeModal={setEditModal} heading="Edit Product" submitButton="Edit Product" dataForm="hello" />}
            {/* Add product modal */}
            {modalOpen &&
              <AddProduct closeModal={setModalOpen} heading="Add Product" submitButton="Add Product" />
            }
            {/* Avatar Config Open */}
          </div>
          {/* Veify Modal  */}
          {verifyModal &&
            <div className='verificationModalContainer'>
              <div style={{
                width: "2vw",
                fontSize: "xx-large"
              }} onClick={() => {
                setVerifyModal(false);
              }}>
                &larr;
              </div>
              <VerificationModal />
            </div>
          }
          {/* --------ACCOUNT--------- */}
          <div className="Account">
            {!modalOpen &&
              <div className="w-full h-full" style={{ background: 'rgb(34,193,195)', overflow: 'hidden' }}>
                <div className="box1 ">
                  <div className='logoOutBtnAccount' >
                    {/* <span><img src={help} alt="" /></span> */}
                    <span onClick={logout}><img src={exit} alt="" className='pr-2' /></span>
                  </div>
                  <div className="userimg">
                    <img className={`w-full userimg1 ${verified && 'toBeVerified'} `} src={gender} alt="Profile" />
                    {/* <div className='verficationIcon'>
                    <img  src={verify} alt="" width="20px" />
                    </div> */}
                  </div>
                  <div className="topsection p-0 text-white">
                    <span className="nameAccount text-white"><span className='font-thin'>Hello </span><span className='font-bold'>{firstname ? firstname : 'There'}</span></span>
                  </div>
                  <div className='flex justify-center items-center text-white text-md pb-2'>
                    <img src={locationIcon} alt="location" className='h-4 w-4' />
                    {collegeName ? collegeName : "Complete your profile!"}
                  </div>
                  {/* {verified==='true' ? (
                    <div className="verifiedBtn">
                      <span>Verified</span>
                      <span className='ms-1'><img src={verify} alt="" width="20px" /></span>
                    </div>
                  ) :
                    (
                      <div className='verfiyBtn my-2' onClick={verifyModalHandler}>
                        <span>Click To Verifiy</span>
                      </div>
                    )} */}
                    {
                      verified === "true" && 
                      <div className="verifiedBtn">
                        <span>Verified</span>
                      <span className='ms-1'><img src={verify} alt="" width="20px" /></span>
                    </div>
                    }
                    {
                      verified === "pending" &&
                      <div className="verfiyBtn my-2">
                        <span>Waiting for confirmation</span>
                      </div>
                    }
                    {
                      verified === "false" &&
                      <div className='verfiyBtn my-2' onClick={verifyModalHandler}>
                        <span>Click To Verifiy</span>
                      </div>
                    }
                  {/*  */}
                </div>
                <div className=' bottomAccountContainer '>
                  <div className='accountBtnContainer'>
                    <div className='editBtnBox pt-2 ' onClick={handeleditClick}>
                      <img className='EditBtnImg' src={EditBtn} alt="" />
                      <button className='text-md font-light'>Edit Profile</button>
                    </div>
                    <div className='accountBtnBox'>
                      <div className='addBtn pt-2' onClick={openModal}>
                        <img className='EditBtnImg' src={Add} alt="" />
                        <span className='text-md font-light'>Add Products</span>
                      </div>
                    </div>
                    <div className='accountBtnBox'>
                      <div className='addBtn pt-2' onClick={editAvatarOpen}>
                        <img className='EditBtnImg' src={Add} alt="" />
                        <span className='text-md font-light'>Configure Avatar</span>
                      </div>
                    </div>
                  </div>
                  {edit ?
                    (<div className="EditAccount px-6 py-2 overflow-scroll">
                      <form className="w-full">
                        <div className="md:flex md:items-center mb-6">
                          <div className="md:w-1/3">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                              Full Name
                            </label>
                          </div>
                          <div className="md:w-2/3">
                            <input className="form-control bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-password1" type="text" value={userNameEdit} onChange={editName} />
                          </div>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                          <div className="md:w-1/3">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                              Mobile Number
                            </label>
                          </div>
                          <div className="md:w-2/3">
                            <input className="form-control bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="exampleTel0" type="number" placeholder="Enter Mobile No. "
                              value={userMobileEdit}
                              onChange={editMobile} />
                          </div>
                        </div>

                        <div className="md:flex md:items-center mb-6">
                          <div className="md:w-1/3">
                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                              Email ID
                            </label>
                          </div>
                          <div className="md:w-2/3">
                            <input
                              type="email"
                              className="form-control bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                              id="exampleEmail0"
                              value={userEmailEdit}
                              onChange={editEmail} />
                          </div>
                        </div>
                        <button className='editDoneBtn' onClick={handelEditDone}>Done</button>
                      </form>
                    </div>)
                    :

                    (
                      <div className='productListContainer'>
                        <h3 className='ms-3'>My Products</h3>
                        <ul className='productsList'>
                          {userProd.map(prods => {
                            return (
                              <li className='productsListItem px-2'>
                                  <div className="prodDet">
                                <Link to={`/product/${prods._id}`}>

                                  <div className='flex items-center'>
                                    <img src={`${baseUrl}/photo/get-photo/${prods.pImages[0]}`} alt="product_image" className='object-cover rounded-lg' />
                                    <div className='px-3 flex flex-col'>
                                      <span>{prods.pName}</span>
                                      <span>₹{prods.pPrice}</span>
                                    </div>
                                  </div>
                                </Link>
                                  </div>
                                  <div>
                                    <span onClick={(e) => sweetAlert(prods._id)}>
                                      <i className="fa-solid fa-trash text-danger"></i>
                                    </span>
                                    <span onClick={editModalOpen}>
                                      <i className="fa-solid fa-pencil ms-3 me-2"></i>
                                    </span>
                                  </div>
                              </li>
                            )
                          })}
                        </ul>

                      </div>
                    )
                  }
                </div>
              </div>
            }
          </div>

        </div>
        <Footer />
      </div>
    )
  } else {
    return (
      <div className="flex items-center justify-center p-8">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    )
  }
}