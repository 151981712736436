import Footer from '../../layouts/Footer';
import React, { useState, useEffect, useContext } from "react";
// import p1 from "../../assets/p1.jpeg"
// import p2 from "../../assets/p2.jpeg"
// import p3 from "../../assets/p3.jpeg"
// import "./products.css";
import ProductCard from '../Home/components/Products/Product';
import Product from "../Home/components/Products/Product";
import { getAllProducts } from "../../services/product";
import './products.css'
import { getAllCategories } from '../../services/category';
import { getProductsByCategoryName } from '../../services/product';
import FeaturedProducts from './FeaturedProducts';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

// import { useLocation } from 'react-router-dom';
export default function Products() {
  let collegeId = JSON.parse(localStorage.getItem("collegeId"));
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [update, setUpdate] = useState(" ");
  useEffect(() => {
    let getProds = async (category,collegeId) => {
      let p = await getProductsByCategoryName(category,collegeId);
      return p;
    }
    let a = async () => {
      let b = await getAllProducts(collegeId);
      let c = await getAllCategories();
      if (c) {
        setCategories(c.Categories);
        categories?.map(async (cat) => {
          let prod = await getProds(cat.cName,collegeId);
          let catName = cat.cName;
          let obj = {};
          obj[catName] = prod;
          let temp = products;
          temp.push(obj);
          setProducts(temp);
        })
        if(update < 3) setUpdate(update+1);
        //categories.map((cat, i) => {
        //  console.log(Object.keys(products[i]), i, Object.values(products[i]));
        //  console.log(cat.cName, i);
        //  // let temp = Object.values(products[i]);
        //  // Object.values(products[i])[0].map((p) => {
        //  //   console.log('ind', p.pName, p.pDescription, p.pPrice);
        //  // })
        //})
      }
    };
    a()
  },[update,collegeId]);
  return (
    <>

      <div className='products-list productpage-anim' >
        <h1 className='m-2 p-2 place-content-center items-center content-center'>Products
        </h1>
        <FeaturedProducts />
        {/* Item 1 */}
        {categories ?
          categories.map((category, i) => {
            return (
              <div className='categories-holder'>
                <div className='list_header'>
                  <ul className='flex justify-between items-center list-none p-0 text-xl m-2'>
                    <li className="categoriesName-container">
                      {products[i] ? Object.keys(products[i]) : ''}
                    </li>
                    <Link to={`/category/${products[i] ? Object.keys(products[i]) : ''}`}>
                      <li>
                        View All &#x2192;
                      </li>
                    </Link>
                  </ul>
                </div>
                <div className=" listed-products-container grid grid-cols-2 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-1">
                  {
                    products[i] ? 
                    (Object.values(products[i])[0]?.map((p) => {
                      return (
                        <Product
                          className="productRenderingCard"
                          pName={p.pName}
                          pPrice={p.pPrice}
                          pImage={p.pImages}
                          link={p._id}
                        />
                      );
                    })) : (
                      <div>No Product Found</div>
                    )
                  }
                </div>
              </div>
            )
          }) : (
            <div>Loading</div>
          )
        }
      </div>
      <Footer />
    </>
  )
}