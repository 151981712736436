import React from 'react';
import Navbar from '../../layouts/Navbar';
import DesktopCategoryNavbar from './DesktopCategoryNavbar';
import Products from '../../components/Products';
import "./DesktopCategoryNavbar.css";
const DesktopCategoryPage = () => {
  return (
    <>
      <Navbar/>
    <div style={{height:"13vh"}}>
    </div>
    <DesktopCategoryNavbar />
    <div style={{height:"70px"}}>
    </div>
    <Products />
    </>
  )
}

export default DesktopCategoryPage