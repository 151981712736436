import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});

const BearerToken = () => 
    localStorage.getItem("jwt") ? JSON.parse(localStorage.getItem("jwt")) : false;

export async function postConversation(senderId,receiverId,receiverName) {
    let roomid = (senderId > receiverId) ? senderId+receiverId : receiverId+senderId;
    const conversation = await instance.post(`/conversations/`,{roomid,senderId,receiverId,receiverName},{
        headers : {
            Authorization : "Bearer "+BearerToken()
        }
    });
    return  conversation;
}

export async function getConversations(userId) {
    const conversations = await instance.get(`/conversations/${userId}`,{
        headers : {
            Authorization : "Bearer "+BearerToken()
        }
    })
    return conversations;
}

export async function postMessage(authorName,authorId,roomid,message) {
    const messageResponse = await instance.post(`/messages`,{authorName,authorId,roomid,message},{
        headers : {
            Authorization : "Bearer "+BearerToken()
        }
    })
    return messageResponse;
}

export async function getMessagesInConversation(roomid) {
    console.log(roomid);
    const messages = await instance.get(`/messages/${roomid}`,{
        headers : {
            Authorization : "Bearer "+BearerToken()
        }
    });
    return messages;
}