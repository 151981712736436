import { createContext, useEffect, useState } from "react";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});

export const CollegeContext = createContext();

export const CollegeContextProvider = ({children}) => {
    const [currentCollege,setCurrentCollege] = useState({});
    let collegeId = JSON.parse(localStorage.getItem("collegeId"));

    useEffect(() => {
        const getCollegeData = async() => {
            const collegeResponse = await instance.get(`college/${collegeId}`);
            setCurrentCollege(collegeResponse.data);
        }
        getCollegeData();
    },[]);

    return (
        <CollegeContext.Provider value={{currentCollege}}>
            {children}
        </CollegeContext.Provider>
    );
}