import React from 'react'
import Lottie from 'react-lottie';
import Error from '../../assets/error404.json'
import logo from '../../assets/logo.png'
const Error404 = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Error,
    renderer: 'svg'
  }
  return (
    <>
    <div className='errorPageHeader'>
      <img src={logo} alt="" />
    </div>
    <div className='errorPage'>
      <Lottie
        options={defaultOptions}
        height={250}
        width={250}
        />
      <div className='errroPageMsg'>Page Not Found</div>
    </div>
    </>
  )
}
export default Error404;