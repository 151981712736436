import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from '../../../context/AuthContext'
import { ChatContext } from "../../../context/ChatContext";

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();
  const bottomRef=useRef(null)
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message.msgBody.message]);
    // console.log(message.senderID);
  return (
    <div
      ref={ref}
      className={`message ${message.fromUserId === currentUser._id ? "owner" :" "}`}
    >
      <div className="messageInfo">
        {/* <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : data.user.photoURL
          }
          alt=""
        /> */}
        {/* <span>just now</span> */}
      </div>
      <div className="messageContent">
        <p className={`singleMessage message${message.fromUserId === currentUser._id ? "owner": "reciver"}`}>{message.msgBody.message}</p>
        {message.img && <img src={message.img} alt="" />}
      </div>
      <div ref={bottomRef}/>
    </div>
  );
};

export default Message;