import React, { useState, useEffect } from "react";
import logo from "../images/logo.png";
import "./navbarStyle.css";
import MobileNavbar from "./MobileNavbar";
// import Login from "./LoginSignup/Login";
// import Signup from "./LoginSignup/Signup";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import Modal from "./userModal";
import Button from "./button";
import CollegesModal from './CollegesModal'

export default function Navbar() {

  const handleClick = () => {
    document.getElementById("collegeModal").style.display = "block";
  };
  document.addEventListener("click", (event) => {
    if (event.target == document.getElementById("collegeModal")) {
      document.getElementById("collegeModal").style.display = "none";
    }
  });



  const auth = isAuthenticated();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 801);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const [login, setLogin] = useState(true);
  const [signup, setSignup] = useState(false);
  const loginHandler = () => {
    setLogin(true);
    setSignup(false);
  };
  const signupHandler = () => {
    setLogin(false);
    setSignup(true);
  };
  const [isShown, setIsShown] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const handelCollegeClick = () => {
    setIsShown(true);
    setIsVisible(true);
  };
  const loginModal = () => {
    setIsLogin(true);
  };
  const closeLoginModal = (event) => {
    setIsLogin(false);
    setLogin(true);
    setSignup(false);
  };
  const closeModal = (event) => {
    console.log(event.currentTarget);
    setIsShown(false);
    setIsVisible(false);
    setIsLogin(false);
  };
  return (
    <>
      {isDesktop ? (
        <div className="mainContainerS ">
          <div className="logoTitleContainerS">
            <img src={logo} alt="logo" className="logo_S" />
            <a href="/home" className="titleS">
              Sellular
            </a>
          </div>
          <div className="listContainerS">
            <ul className="navList">
              <div onClick={handleClick}>
                <Button buttonName="College" />
                <div id="collegeModal" className="modalCollege">
                  <div className="CollegeModalContainerS">
                        <CollegesModal/>
                  </div>
                </div>
              </div>
              <Link to="/events">
                <Button buttonName="Events" />
              </Link>
              <Link to="/products">
                <Button buttonName="Products" />
              </Link>
              <Link to="/chat">
                {auth ? <Button buttonName="Chat" /> : null}
              </Link>
              {auth ? (
                <Modal />
              ) : (
                <li>
                  <button className="activeS" onClick={loginModal}>
                    Login/Signup
                  </button>
                </li>
              )}
            </ul>
          </div>

          {isLogin ? (
            <div className="collageModal">
              <div className="Modal">
                <div className="closeMark" onClick={closeLoginModal}>
                  <i className="fa-solid fa-xmark ms-auto"></i>
                </div>

                <div className="loginPage">
                  {/* {login && <Login />}
                  {signup && <Signup />} */}
                  {login && (
                    <div>
                    <a href="https://api.sellular.club/forgot-password">
                    <p className="bottom-text mt-2 messageText">Forgot Password?</p>
                    </a>
                      <p
                        onClick={signupHandler}
                        className="bottom-text mt-2 messageText"
                      >
                        Don't have an account ?{" "}
                        <span className="logInSignUpButton">SignUp</span>
                      </p>
                    </div>
                  )}
                </div>
                {signup && (
                  <div>
                    <p className="bottom-text mt-2 messageText">
                      Have an account{" "}
                      <span
                        onClick={loginHandler}
                        className="logInSignUpButton"
                      >
                        Login
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div className="clearFix"></div>
        </div>
      ) : (
        <MobileNavbar />
      )}
    </>
  );
}
