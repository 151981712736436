import { createContext, useEffect, useState } from "react";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  let userId = JSON.parse(localStorage.getItem("userId"));
  
  useEffect(() => {
      const unsub = async() => {
        const user = await instance.get(`/singleUserInfo/${userId}`);
        console.log("User data",user)
        setCurrentUser(user.data);
    }
    unsub();
  }, [userId]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
