import React from "react";
import "./categories.css";
import CategoryCards from "./Products/categoryCards";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCategories } from "../../../services/category";
import LazyLoad from 'react-lazy-load';
export default function Categories() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let a = async () => {
      let c = await getAllCategories();
      if(c){
        setCategories(c.Categories)
      }
    };
    a();
  }, []);
  const baseUrl = process.env.REACT_APP_API_URL
  const imageUrl = baseUrl+"/photo/get-photo/";
  return (
    <>
    {
      categories ?
      <div className="category_CardRenderingS">
        {categories.map(category => {
          return(
            <LazyLoad threshold={1} onContentVisible={() => {console.log('loaded!')}}>
            <CategoryCards
            imgName={imageUrl+category.cImage}
            Title={category.cName}
            description={category.cDescription}
            />
            </LazyLoad>
          )
        })}
      </div>
      :
      <div style={{fontSize:'2rem'}}>Loading</div>
    }
    </>
  );
}

    