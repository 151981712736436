import Category from "./Category.js";
import Products from "../../components/Products";
import { useParams } from "react-router-dom";
import Footer from "../../layouts/Footer.jsx";
import { CategoryContextProvider } from "../../context/CategoryContext.js";
function CategoryPage() {
  const l = useParams();
  // console.log(l.name)
  return (
    <>
    <CategoryContextProvider>
      <Category name={l.name} />
      <Products name={l.name} />
      <Footer/>
    </CategoryContextProvider>
    </>
  );
}

export default CategoryPage;
