import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import Navbar from '../../layouts/Navbar'
import Footer from '../../layouts/Footer'
import EventsCards from './EventCards'
import CategorySectionEvents from './CategorySection'
import './style.css'

export default function EventsPage() {
  const[category, setCategory] =  useState('hackathon');
  const[overlay, setOverlay] = useState(false);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  
    if(params.category) {
      setCategory(params.category);
    }
  }, [])
  
   
  return (
    <>
        <Link to={`/events/category`}>
            <div className={overlay ? 'event-modal-overlay' : ''}>
            <Navbar/>
                <CategorySectionEvents selectCategory={setCategory} />
                <EventsCards category={category} overlay={setOverlay}/>
            <Footer/>
            </div>
        </Link>
    </>
  )
}
