import React from 'react'
import './style.css'
export default function EventCards(props) {
    return (
        <>
            <div className='eventCardsContainer' onClick={props.function}>
                <div className='eventCardsImageContainer'>
                    <img src={props.image} href="event-poster" />
                </div>
                <div className='eventCardsTextcontainer'>
                    <h3 className='eventCardsHeading'>{props.title}</h3>
                    <p style={{margin: '0 1em', padding: '0'}}>{props.location} &#128205;</p>
                    {/* <p>{props.description}</p> */}
                    <div className='eventDateAndTimeContainer'>
                        <span className='eventCardsDate'>{props.date}</span>
                        <span className='eventCardsTime'>{props.time}</span>
                    </div>
                    <div className='registerButtonContainer'>
                        <button className="eventCardsButton">Click for details &rarr;</button>
                    </div>
                </div>
            </div>
        </>
    )
}