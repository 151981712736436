import { useState } from "react"
import { Link } from "react-router-dom"

import img1 from "../images/discord.png"
import img2 from "../images/facebook.png"
import img3 from "../images/instagram.png"
import styles from "./ProductPreview.module.css"
const baseUrl = process.env.REACT_APP_API_URL

export default function ProductPreview(props) {
    const [img, setImg] = useState(`${baseUrl}/photo/get-photo/${props.pImage[0]}`);

    const changeImgHandler = (e) => {
        setImg(e.target.getAttribute('src'))
    }

    return(
        <div className={styles.mainContainer}>
            <div className={styles.mainImage}>
                <img src={img} />
            </div>
            <div className={styles.previewContainer}>
                <img src={`${baseUrl}/photo/get-photo/${props.pImage[0]}`} className={styles.previewImage} onClick={changeImgHandler} />
                <img src={`${baseUrl}/photo/get-photo/${props.pImage[1]}`} className={styles.previewImage} onClick={changeImgHandler} />
                <img src={`${baseUrl}/photo/get-photo/${props.pImage[2]}`} className={styles.previewImage} onClick={changeImgHandler} />
            </div>
            <div className={styles.detailsContainer}>
                <div className="text-xl">
                    {props.pName}
                </div>
                <div className="text-xl font-black">
                    ₹{props.pPrice}
                </div>
            </div>
            <div>
                <Link to={props.link ? `/product/${props.link}` : ""}>
                    <div className='w-full'>
                        <button className='text-white px-3 py-2 rounded-lg' style={{backgroundColor: '#4d97a7'}}>Know more</button>
                    </div>
                </Link>
            </div>
        </div>
    )
}