import React, { useState, useEffect } from "react"
import SocialmodalBottom from './SocialmodalBottom'
import SocialmodalTop from './SocialmodalTop'
// import { useNavigate } from "react-router-dom";
export default function socialmodal() {

    // const [product, setProduct] = useState([{ pName: "Loading", pPrice: "Loading", pDescription: "Loading", pImages: ["//unsplash.it/500/500"] }]);
    // const Navigate = useNavigate();
  return (
    <div className='topopenbody '>
        <div className='topSideBox'>
        <SocialmodalTop/>
        </div>


<div className='bottomSideBox'>
<SocialmodalBottom/>
</div>

</div>
  )
}
