import React, { useEffect,useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { getRecommendedProducts } from '../../services/product';

const baseUrl = process.env.REACT_APP_API_URL

export default function FeaturedProducts() {
    // const history = useHistory();
    const[products, setProducts] = useState([]);
    useEffect(() => {
        let a = async () => {
            let p = await getRecommendedProducts();
            setProducts(p);
            console.log(p);
        }
        a();
    }, [])
    return (
        <div className='featured-products'>
            <div className=''>
                <div className='w-full inline p-3'>
                    Featured Products
                </div>
            <div/>
            
                <div className='flex overflow-x-auto overflow-y-hidden mt-2'>
                {products && products.length > 0 ? (
                  products.map( (item, key) => {
                    return (
                      <Link to={`/product/${item._id}`}>
                        <div className="relative m-1 bg-origin-content rounded-lg">
                          <div className="h-56 w-40 md:w-56 w-40 md:w-56 flex justify-center">
                            
                            <img
                              onClick={() => {
                                // history.push(`/products/${item._id}`);
                                // window.location.reload();
                              }}
                              className="border h-32 w-32 object-cover object-center cursor-pointer rounded-lg bg-gray-200"
                              src={item.pImages ? `${baseUrl}/photo/get-photo/${item.pImages[0]}` : ''}
                              alt=""
                            />
                            
                          </div>
                          <div className="border -mt-52 h-40 rounded-lg bg-origin-content bg-gray-100 px-2 flex justify-center">
                            <div className=" items-center  justify-center mt-2 bottom-0 mb-3  position-absolute pb-2">
                              <div className="text-sm font-medium truncate text-black">
                                {item.pName}
                              </div>
                            </div>
                            <div className="text-sm text-center bottom-0 position-absolute pb-1 text-black">
                              ₹{item.pPrice}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <span>
                      No product found
                      </span>
                )}
                </div>
                </div>
            </div>

    );
}
