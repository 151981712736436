import React, { useEffect, useState } from 'react';
import "./Category.css";
// import '../Products/products.css';
import image from '../../images/rentingimg.jpg'
import Productcards from '../Products/Productcards';
import { getAllCategories } from '../../services/category';

const Category = (props) => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        let a = async () => {
            let c = await getAllCategories();
            if (c) {
                setCategories(c.Categories)
            }
        };
        a();
    }, []);
    const baseUrl = process.env.REACT_APP_API_URL
    const imageUrl = baseUrl + "/photo/get-photo/";
    // const[isClick,setIsclick] = useState(true);
    // useEffect(() =>{
    //     if({props.name} === {category.cName}){
    //         setIsclick(1);
    //     }
    //     else{
    //         setIsclick(false);
    //     }
    // })
    return (
        <>
            <div className='category-header'>
                <div className='category-back-btn'>
                    <a href='../'>&#8592;</a>
                </div>
                <h3>
                    {props.name}
                </h3>
            </div>

            <div className='category '>

                <div className='categories-listing-container'>
                    {categories.map(category => {
                        return (
                            <Productcards
                                imageName={imageUrl + category.cImage}
                                categoryTitle={category.cName} />
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Category