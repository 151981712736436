import Navbar from '../../layouts/Navbar';
import Carousel from './components/HomePageCarousel/Carousel';
import Categories from './components/Categories';
import ProductsListing from './components/ProductsListing';
// import Footer from '../../layouts/DesktopFooter';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Footer from '../../layouts/Footer';
import "./Home.css";
// import WaitListing from '../../layouts/LoginSignup/waitlist/WaitList';
// import GetStarted from '../../layouts/GetStarted/GetStarted';
// import WaLogin from '../../layouts/LoginSignup/WaLogin';
// import CollegeDetails from '../../layouts/LoginSignup/waitlist/CollegeDetails';
// import LoginSignup from '../../layouts/LoginSignup/LoginSignup.jsx';
import NoConection from '../ErrorPage/NoConection';
import StartingAt from './components/ProductSections/StartingAt';
import ProductStarting from './components/ProductSections/ProductStarting';
// import WaitList from '../../layouts/LoginSignup/waitlist/WaitList';
export default function Home(props) {
    const l = useParams();
    if (l.token) {
        localStorage.setItem("jwt", JSON.stringify(l.token));
    }
    // const [error,setError]=useState(false);
    // if(props.errorMsg==="true"){
    //     setError(true);
    // }
    // else{
    //     setError(false);
    // }
    return (
        <div>
            {/* {error && <NoConection/>
            } */}
            {/* <WaitList/>  */}
        {/* 
            <GetStarted/>
        */}
        {/* <CollegeDetails/> */}
        {/* <LoginSignup/> */}
        {/* <WaLogin/> */}
        <marquee behavior="" direction="">Beta Testing:<a href="https://forms.gle/4p5xQBv6J5VPDZYw7">Form link for Feedback</a> </marquee>
            <Navbar />
            <Carousel />
            <Categories />
            <ProductsListing />
                <ProductStarting />
            <StartingAt />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </div>
    )
}