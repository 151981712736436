
import axios from "axios";
import swal from 'sweetalert';
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});


const BearerToken = () => 
    localStorage.getItem("jwt") ? JSON.parse(localStorage.getItem("jwt")) : false;

export async function editUserData(uId, name, phoneNumber,gender,dob) {
    try {
        let editDataResponse = await instance.post("/user/edit-user",{uId, name, phoneNumber,gender,dob},{
            headers : {
                Authorization : "Bearer "+BearerToken(),
            }
        })
        // console.log("edited data : ",editDataResponse);
        return editDataResponse.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getUsername(userId) {
    try {
        let usernameResponse = await instance.post("/user/get-username",{userId});
        return usernameResponse.data.User.name;
    } catch (error) {
        console.log(error);
    }
}