import React from 'react'
import { useEffect, useContext} from 'react';
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CategoryContext } from '../../context/CategoryContext';
// import './products.css'
export default function Productcards(props) {
  //  const [onClick,setOnclick] = useState(0);

  // const Categ = [
  //   {dis:"/category",i:1}
  // ]
   const current=window.location.pathname;
   const categories1 = props.categoryTitle;
  var category="";
  for(var i=0;i<categories1.length;i++) {
    if(categories1[i]===" ")
    {
      category+="%20";
    }
    else{
      category += categories1[i];
    }
  }

   const[isClick,setIsclick] = useState(false);
   useEffect(()=>{
    if(current === `/category/${category}`){
      setIsclick(true)
    }
    
    else{
      setIsclick(false);
    }
   },[current])

   const { data, dispatch } = useContext(CategoryContext);

  const categoryChangeHandler = () => {
    dispatch({type: 'reset'});
    dispatch({type: 'clear'});
  }

  return (
    <>  
    <NavLink exact to={`/category/${props.categoryTitle}`} className={`linkTagS ${isClick === true ?'activeLink':''}`} onClick={categoryChangeHandler}>
      <div className="imageAndNameBox"  >
        <div className="imgBackgrdcolor">
          <div className='imgContainerHolder'>
            <img src={props.imageName} alt="/"  className={`imgCategory ${isClick === true ?'effectRen':'noneffectRen'}`}/>
          </div>
        </div>
        <span className={`categoryTitleS`}>
          {props.categoryTitle}
        </span>
      </div> 
    </NavLink>
    
    </>
  )
}
