import React, { useContext, useEffect, useState,useRef } from "react";
import { ChatContext } from "../../../context/ChatContext";
import Message from "./Message";
import SDK from "../../../SDK";
import { AuthContext } from "../../../context/AuthContext";
const Messages = () => {
  const bottomRef=useRef(null)
  const [messages, setMessages] = useState([]);
  const { data,dispatch } = useContext(ChatContext);

  // useEffect(()=>{
  //   bottomRef.current?.scrollIntoView({behaviour:'smooth'});
  // },[data]);
  
  // mirror fly
  useEffect(() => {
    const getChatMessages = async() => {
      const {userJid} = await SDK.getJid(data.user.fromUserId);
      const messagesList = await SDK.getChatMessages(userJid);
      setMessages(messagesList.data);
    }
    getChatMessages();
  })
  // console.log("messages with this chat : ",messages);

  return (
    <div className="messages">
      {messages.slice(0).reverse().map((m) => (
        <Message message={m} />
      ))}
      <div ref={bottomRef}/>
    </div>
  );
};

export default Messages;
