import React,{useState} from 'react';

const DesktopCategoryNavbar = () => {
 
  return (
    <>
    <div className='desktopcategorynavbar'>
      <div className='contents'>
        <div className='path'>
            <a href="/" >Home</a>
            {/* <i className="fa-solid fa-chevron-right nexticon" style={{backgroundColor : "#fafafa"}}></i>
            <p>Category Name</p> */}
        </div>
        <div className='othercategories'>
            <a className='btnCatogery' activeClassName="activeCategory" href='#'>Packages</a>
            <a className='btnCatogery' activeClassName="activeCategory" href='#'>Furniture</a>
            <a className='btnCatogery' activeClassName="activeCategory"href='#'>Appliances</a>
            <a className='btnCatogery' activeClassName="activeCategory"href='#'>Electronics</a>
            <a className='btnCatogery' activeClassName="activeCategory"href='#'>Category</a>
        </div>
      </div>
    </div>
    </>
  )
}

export default DesktopCategoryNavbar;