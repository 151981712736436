import "./userModal.css";
import power from "../images/power-off.png";
import Account from "../images/My account.png";
import wishlist from "../images/My wishlist.png";
import dropdown from "../images/down-arrow.png";
import HelpSupport from '../assets/help.png'
import Settings from "../images/settings.png";
import { logout } from "../services/auth";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../context/AuthContext'
import { Link } from "react-router-dom";

function App() {
  const [isVisible, setVisible] = useState(false);
  const [rotateDropdown, setRotateDropdown] = useState(false);

  const handleRotate = () => setRotateDropdown(!rotateDropdown);

  const rotate = rotateDropdown ? "rotate(180deg)" : "rotate(0)";

  const { currentUser } = useContext(AuthContext);
  const handleClick = () => {
    if (isVisible == false) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }
  return (
    <>
      <button className="userButton " onClick={() => {
        handleClick();
        handleRotate();
      }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <div className="userName-icon-container">
          <div>
            {currentUser.displayName}</div>
          <div className="drop-downContainer">
            <img style={{ transform: rotate, transition: "all 0.3s linear" }} src={dropdown} alt="/" />
          </div>
        </div>
      </button>
      <div className="dropDownMenuContainer">
        {isVisible &&
          <ul className="listHolder">
            <li>
              <div className="img-name-container">
                <div className="icon-Container">
                  <img src={Account} alt="/" />
                </div>
                <Link to="/account" className="linker-tag" >
                  <div className="listed-item" >
                    Account
                  </div>
                </Link>
              </div>
            </li>
            <li>
              <div className="img-name-container">
                <div className="icon-Container">
                  <img src={wishlist} alt="/" />
                </div>
                <div className="listed-item"> My ProductList</div>
              </div>
            </li>
            <li>
              <div className="img-name-container">
                <div className="icon-Container">
                  <img src={Settings} alt="/" />
                </div>

                <div className="listed-item"> Settings</div>

              </div>
            </li>
            <li>
              <div className="border-line"></div>
              <div className="img-name-container" id="logout-section">
                <div className="icon-Container">
                  <img src={power} alt="/" />
                </div>
                <div className="listed-item" onClick={logout} > Logout</div>
              </div>
            </li>
            <li>
              <div className="border-line"></div>
              <div className="img-name-container" id="logout-section">
                <div className="icon-Container">
                  <img src={HelpSupport} alt="/" />
                </div>
                <div className="listed-item" >Help and Support</div>
              </div>
            </li>
          </ul>}
      </div>

    </>
  );
}

export default App;
