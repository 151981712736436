import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import './style.css'
import CategoryEvents from './eventsCategory'
export default function CategorySection(props) {
    const navigate = useNavigate()
    const categories = [
        { categoryName: "hackathon" }, { categoryName: "technical" }, { categoryName: "workshop" }, { categoryName: "non-tech" }, { categoryName: "quiz" }, { categoryName: "seminar" }, { categoryName: "club-events" }, { categoryName: "business" }]
    const handleClick =  (event) => {
        navigate(`/events/category`);
        console.log(event.target.innerHTML);
        props.selectCategory(event.target.innerHTML)
    }
        return (
        <>
            <div className="eventsCategoryContainer">
               
                <div className='eventscategoryHeadingContainer'>
                    <h2>Events for you</h2>
                </div>
                    
                    <div className='categoryContainerEvents'>
                        {categories.map(category => {
                            return (
                                <CategoryEvents
                                    eventCategory={category.categoryName}
                                    functionName={handleClick}
                                />
                            )
                        })}
                    </div>
            </div>
        </>
    )
}
