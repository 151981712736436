import "./style.css";
import EventsHomePage from './EventsHomePage'
import Footer from "../../layouts/Footer";
import Navbar from '../../layouts/Navbar';
// import EventsPage from "./eventsPage";
import EventSoon from '../../assets/coming.gif'
export default function Events() {
  return (
    <>
      {/* <EventsHomePage/> */}
      <Navbar />
      <div className="EventsCommingSoon">
        <img src={EventSoon} alt="" />
      </div>
      <Footer />
    </>
  );
}
