import React, { useEffect, useState } from 'react'
import './Carousel.css'
import { getSliderImages } from '../../../../services/images'
import Indicators from '../../../../assets/CarouselComponents/Indicators'
import ImagesCarousel from '../../../../assets/CarouselComponents/ImagesCarousel'
import ButtonsCarousel from '../../../../assets/CarouselComponents/ButtonsCarousel'
const baseUrl = process.env.REACT_APP_API_URL
function Slider() {
  const [images, setImages] = useState(["//unsplash.it/500/500"])
  useEffect(() => {
    let a = async () => {
      let result = await getSliderImages();
      setImages(result.Images);
    }
    a();
}, [])
  
  return (
    <div className='mt-4'>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
        <Indicators/>
        <ImagesCarousel/>
        <ButtonsCarousel/>
      </div>
    </div>
  )
}

export default Slider
