import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../../context/AuthContext'
import { ChatContext } from "../../../context/ChatContext";
import SDK from "../../../SDK";
import { getUsername } from "../../../services/users";
import chat from '../../../assets/chat.json'
import Lottie from 'react-lottie';
import Loader from "../../../components/Loader";
function Chats() {
  const [chats, setChats] = useState([]);
  let chatsWithUsernames = [...chats];

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const [loader,setLoader] = useState(false);
  useEffect(() => {
    const getAllChats = async() => {
      setLoader(true);
      await SDK.getRecentChats().then((allChats) => {
        console.log(allChats);
        const usernameFetch = async() => {
          for(let i = 0; i < allChats.data.length; i++) {
            allChats.data[i].username = await getUsername(allChats.data[i].fromUserId);
          }
          console.log("all the mirror fly chats : ",allChats);
          setLoader(false)
          setChats(allChats.data);
        }
        usernameFetch();
      }).catch((err) => {
        console.log("an error occured ",err);
      });
    }
    getAllChats();
  },[currentUser._id])
  
  console.log(chats);
  const defaultOptions = {
    loop: true,
  autoplay: true,
  animationData: chat,
  renderer: 'svg'
}
  // async function usernameFetch() {
  //   for(let i = 0; i < chats.length; i++) {
  //     console.log("chat userId : ",chats[i].fromUserId);
  //     chatsWithUsernames[i].username = await getUsername(chats[i].fromUserId);
  //     console.log("chat username : ",chatsWithUsernames[i].username);
  //   }
  //   console.log("all chat names : ",chatsWithUsernames);
  //   // setChats(chatsWithUsernames);
  // }
  // usernameFetch();
  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };
let i = 0;
  return (
    <div className="chats">
      {loader ? <Loader /> : 
    chats.length>0?
      <>
      {Object.entries(chats)?.sort((a,b)=>b[1].updatedAt - a[1].updatedAt).slice(0).reverse().map((chat) => (
        <div
          className="userChat"
          key={chat[0]}
          onClick={() => handleSelect(chat[1])}
        >
          <span className="invisible">{i++}</span>
          {console.log("chat being mapped : ",chat[1])}
           <img src={require("../../../assets/peeps/peep-"+i+".png")} alt="" className='h-16 w-16 object-scale-down'/>
          <div className="userChatInfo">
            <span className="text-black">{chat[1].username}</span>
          </div>
        </div>
      ))}
    </>
    :
    <>
      <Lottie
          options={defaultOptions}
          height={250}
          width={250}
        />
    <div className="flex justify-center">No Chat Intialized</div>
    </>
    
  }
    </div>
  );
};

export default Chats;