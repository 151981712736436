import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
// Bearer Token
const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt"))
        : false;
// Axios Config Instance
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
// instance.defaults.headers.common['Authorization'] = BearerToken();
// axios.defaults.headers.common['Authorization'] = BearerToken();
// Simple return function
function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}
// CRUD Products
export const createProduct = async ({
    pName,
    pDescription,
    pImage,
    pCategory,
    pPrice,
    pMrp,
    personId,
    pCollegeId,
    creatorName,
}) => {
    console.log("create product")
    /* Most important part for uploading multiple image  */
    let formData = new FormData();
    console.log(pName)
    console.log(pDescription)
    console.log(pCategory)
    console.log(pPrice)
    console.log(pMrp)
    console.log(personId)
    console.log(pCollegeId)
    console.log(creatorName)
    for (const file of pImage) {
        formData.append("pImage", file);
    }
    /* Most important part for uploading multiple image  */
    formData.append("pName", pName);
    formData.append("pDescription", pDescription);
    formData.append("pCategory", pCategory);
    formData.append("pPrice", pPrice);
    formData.append("pMrp", pMrp);
    formData.append("personId", personId);
    formData.append("pCollegeId",pCollegeId);
    formData.append("creatorName",creatorName);
    try {
        let res = await instance.post(
            '/product/add-product',
            formData,{
                headers: {
                    Authorization : "Bearer "+BearerToken()
                }
            }
        );
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
// Get All Products

export async function getAllProducts(collegeId,page,limit) {
    const result = await instance.get(`/product/all-product/${collegeId}/?page=${page}&limit=${limit}`);
    return returnFunction(result);
}
// Get Product By Id
export async function getProductById(productId) {
    const result = await instance.post("/product/single-product", { pId: productId });
    return returnFunction(result);
}
// Create a New Product
// Update Existing Product by Id
export async function editProduct(productId, name, description, imageIds, categoryId, price, userId) {
    const result = await instance.post("/product/edit-product", { productId, name, description, imageIds, categoryId, price, userId });
    return returnFunction(result);
}
// Delete Product By Id
export async function deleteProductById(productId) {
    const result = await instance.post("/product/delete-product", { pId: productId });
    return returnFunction(result);
}
// ---------------------------------
// Get Recommneded Products
export async function getRecommendedProducts() {
    const result = await instance.get("/recommended/get-recommended");
    return returnFunction(result);
}
// Get Products By CategoryName
export async function getProductsByCategoryName(categoryName,collegeId,page,limit) {
    const result = await instance.get(`/category/${categoryName}&${collegeId}/?page=${page}&limit=${limit}`);
    return returnFunction(result);
}

export const productByUser = async () => {
    let uId = JSON.parse(localStorage.getItem("jwt"));
    console.log(uId)
    try {
        let res = await instance.post(`product/product-by-user`, { jwtT: uId });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};