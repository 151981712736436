import React, { useState, useRef, useContext } from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import {CollegeContext} from "../../context/CollegeContext";
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import './verifictionModal.css'
import 'react-image-crop/dist/ReactCrop.css'
import { uploadImages } from '../../services/images'
import { uploadCollegeId, uploadCollegeEmailId } from '../../services/collegeIdUpload'
import swal from 'sweetalert'
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
function VerificationModal() {
  const previewCanvasRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)
  const [fileName, setFileName] = useState("No File Selected");
  const [image, setImage] = useState(null);
  const removeImage = () => {
    setImage(null);
    setCompletedCrop();
  }
  async function onSelectFile(e) {
    console.log(e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      await setFileName(e.target.files[0].name);
      await setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImage(reader.result?.toString() || '')
      }
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )
  const handleIdUpload = async () => {
    var byteString = atob(image.split(',')[1]);
    // separate out the mime component
    var mimeString = image.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    //New Code
    let uploadBlob = new File([ab], "test." + mimeString.split("/")[1], { type: mimeString });
    // var fd = new FormData();
    if (uploadBlob) {
      console.log("Uploading BLOB")
      let a = await uploadImages([uploadBlob])
      console.log("Uploaded BLOB")
      console.log(a)
      function checkEmpty(arr) {
        if (arr.length > 0) return arr
      }
      let b = a.imageIds.filter(checkEmpty)
      console.log(b)
      let c = await uploadCollegeId(b[0])
      console.log(c)
      if (c.data === "Uploaded Successfully") {
        setLoading(false)
        await swal({ text: "Uploaded Successfully" });
        window.location.reload(true);
      } else {
        await swal({ text: "An Error Occured , Can't upload photo", icon: "error" });
      }
      // fd.append("images", uploadBlob);
    }
  }

  const [email,setEmail] = useState('');
  const {currentCollege} = useContext(CollegeContext);
  const handleEmailIdUpload = async() => {
    console.log("email to be uploaded : ",email);
    let result = await uploadCollegeEmailId(email,currentCollege.collegeName);
    setEmail('');
    if(result.success) {
      setLoadingEmail(false);
      await swal({text : "Uploaded successfully!"});
      window.location.reload(true);
    } else {
      setLoadingEmail(false);
      await swal({text : "An error occured!", icon : "error"})
    }
  }

  return (
    <div className='verificationConatainer'>
      <div className='verificationUsingEmail'>
        <div className='addProduct-input-container mb-3'>
          <input type='text' className='addProduct-input' autoComplete="off" required="required" onChange={(e) => setEmail(e.target.value)}/>
          <label className='addProduct-Lable'>College Email Id</label>
        </div>
      </div>
        <div
            onClick={(e) =>  handleEmailIdUpload() && setLoadingEmail(true)}
            className="logInSignUpBtn px-2 m-5"
          >
            Upload
          </div>
      <div className='OrInVarefiction'>
       <span>OR</span> 
      </div>
      {image ? (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={image}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      ) :
        <form className='verficationForm' onClick={() => document.querySelector(".input-field").click()}>
          <i className="fa-solid fa-cloud-arrow-up "></i>
          <input className='input-field' accept='image/*' type="file" hidden onChange={(e) => onSelectFile(e)} multiple />
          <span>Click To ADD ID Card</span>
        </form>
      }
      <div>
        {completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
      <div className=' filesContainer my-3'>
        <span>
          <i className="fa-solid fa-file-arrow-up ms-2 me-3"></i>
          <span>{fileName}</span>
        </span>
        <i onClick={removeImage} className="fa-solid fa-trash me-3"></i>
      </div>
      {loading ? (
        <button
          className="btn btn-primary"
          style={{ background: "teal" }}
          type="button"
          disabled
        >
          <span
            className="spinner-border spinner-border-sm bg-teal"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          Loading...
        </button>
      ) : (
        <>
          {" "}
          <div
            onClick={(e) => handleIdUpload() && setLoading(true)}
            className="logInSignUpBtn px-2 m-5"
          >
            Upload
          </div>
        </>
      )}
      {/* <button className='verifyUploadBtn' onClick={handleIdUpload}>
            Upload
          </button> */}
      {/* Scale and rotate and toggle aspect ratio functionality can be implemented later on */}
      {/* <div>
          <label htmlhtmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!image}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlhtmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!image}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div> */}
    </div>
  )
}

export default VerificationModal