import './modal.css';
import React from 'react'

export default function collegeCards(props) {
  return (
    <div className="collegeCardContainerS">
    <img src={props.collegeImage} />
    <p className="collegeNameS">{props.collegeName}</p>
  </div>
  )
}