import {useContext } from 'react';
import './Waitlist.css';
// import Share from './gif2.json';
import Waitlist from '../../../assets/gif1.json';
import Lottie from 'react-lottie';
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CollegeContext } from '../../../context/CollegeContext';
import Navbar from '../../Navbar';
import test from '../../../assets/gif1.json'
function WaitList() {
  const { currentCollege } = useContext(CollegeContext);
  console.log("this is the college data ", currentCollege);
  let EventTarget = null;
  let Reached = null;//Number of people in the waiting list
  if (currentCollege.target) {
    EventTarget = currentCollege.target;
    Reached = currentCollege.numberOfUsers;
  }
  let counterNum=0;// runnig counter on evry reload 
  let i=0;
  let percentage=0;
  for (i=0; i<Reached; i++) {
    counterNum=counterNum+1;
    percentage = Math.round((counterNum / EventTarget) * 100);
  }
  //const EventTarget = 1000; //Target to be achieved to get acces to buy sell platform
  //Function to run counter on reload
  //  function updateCounter(){
  //    if (counterNum < Reached) {
  //      setCounterNum(counterNum+1);
  //    } else {
  //      setCounterNum(Reached);
  //    }
  //  }
  //  console.log(counterNum);
  //  useEffect(() => {
  //      setTimeout(updateCounter, 1000); //Adding dealy in counter
  //    }, [counterNum])
    const defaultOptions = {
      loop: true,
    autoplay: true,
    animationData: Waitlist,
    renderer: 'svg'
  }
  // Data to send in link
  const dataToPass = {
    file:{test},
    title: 'Join the [number] of users who are already experiencing the benefits of our app. ',
  };
  
  // Share Button
  function shareAcross() {
    if (navigator.share) {

      navigator.share(dataToPass)
        .then(() => console.log('successful share'))
        .catch(error => console.log('error sharing', error));
    } 
    else {
      console.log('No support');
    }
  }
  // Progressbar
  const Parentdiv = {
    height: '1rem',
    width: '90%',
    background: 'transparent',
    border: '2px solid #fff',
    borderRadius: 40,
  }
  
  const Childdiv = {
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: '#fff',
    borderRadius: '40px 0 0 40px',
    textAlign: 'right'
  }

  const progresstext = {
    padding: 10,
    color: '#fff',
    fontWeight: 900,
    marginTop: '25px'
  }
  return (
    <div>
         <Navbar/>
      <div className="WaitList">
        <Lottie
          options={defaultOptions}
          height={250}
          width={250}
        />
        <div className={`WaitList-Counter ${ EventTarget ?"Waitlist":"CommingSoon"}`} >
          {/* <div className='WaitList-Counter-ComingSoon'>
              <span>Coming Soon....</span>  
            </div> */}
          {EventTarget ?
            <>
              <div>
                COLLEGE IN WAITLIST
              </div>
              <div className="WaitList-Counter-OnBoard">
                <div style={Parentdiv}>
                  <div style={Childdiv}>
                    <span id='triangle'></span>
                  </div>
                </div>
                <span style={progresstext}>{`${percentage}%`} registrations</span>
              </div>
              <div>
                <span>Uh oh!</span>
                <br />
                <span>
                  Your College is in waitlist due to less registrations. Don't worry you can share Sellular in your college to get Sellular in your College.
                </span>
              </div>
              <div className="WaitList-Share-Conatiner">
                <button className="WaitList-Share" onClick={() => shareAcross(dataToPass)}>
                  <span>Share</span>
                </button>
              </div>
            </>
            :
            <div className='WaitList-Counter-ComingSoon'>
              <span>Coming Soon....</span>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default WaitList;
