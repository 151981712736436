
import axios from "axios";
// import { signOut } from "firebase/auth";
import swal from 'sweetalert';
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});

export const isAuthenticated = () =>
    localStorage.getItem("jwt") ? JSON.parse(localStorage.getItem("jwt")) : false;
// Sign Up using name email password and check password

// make separate functions for user signup and patch college data
export async function signUp(name, email, password, cPassword,dob,state,city,college) {
    const collegeResult = await instance.patch("/college",{
        collegeName : college,
        collegeState : state,
        collegeCity : city
    })
    const collegeId = collegeResult.data.collegeId;
    localStorage.setItem("collegeId",JSON.stringify(collegeResult.data.collegeId));
    const userResult = await instance.post("/signup", { name, email, password,cPassword,dob, college, collegeId });
    if (userResult.data) {
        if (userResult.data.success)
            return { "token": userResult.data.success, "inWaitlist":collegeResult.data.inWaitlist }
        else {
            return { "error": userResult.data.error }
        }
    } else {
        console.log(userResult);
    }
}


// Log in using email and password
export async function logIn(email, password) {
    const result = await instance.post("/signin", { email, password });
    console.log(result)
    if (result.data) {
        if (result.data.token) {
            localStorage.setItem("jwt", JSON.stringify(result.data.token));
            // the below item will be used to verify the user
            // (so that you are not verified in case you enter any random jwt token)
            localStorage.setItem("userId",JSON.stringify(result.data.user._id));
            localStorage.setItem("collegeId",JSON.stringify(result.data.user.collegeId));
            console.log("This is the result of login function : ",result);
            return { "token": result.data.token, "user":result.data.user }
        }
        else {
            return { "error": result.data.error }
        }
    } else {
        console.log(result);
    }
}

// Logout

export const logout = () => {

    swal({
        title: "Log Out??",
        icon: "warning",
        buttons:true,
      })      
      .then((willDelete) => {
        if(willDelete) {
            swal({
                title: "Logged Out",
                icon: "success",
                button:true,
            })
            localStorage.removeItem("jwt");
            localStorage.removeItem("userId");
            localStorage.removeItem("collegeId");
            localStorage.removeItem("Password");
            localStorage.removeItem("UserID");
            window.location.reload(true);
            // signOut();
        } else {
            return
        }
        });
    // localStorage.removeItem("cart");
    // localStorage.removeItem("wishList");
    
};