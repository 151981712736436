import React from 'react'
import "./cardsCss.css"
const ProductStarting = () => {
  return (
        <section className='ProductSections'>
        <div class="grid-2">
            <div class="grid-carousel"><img src="//unsplash.it/900/900" alt="" class="skeleton" />
            <p>
                <div class="discount">60% off</div>
                <div class="product-info ">₹399.00 <span class="strike">MRP <s>₹499.00</s></span></div>
            </p>
            </div>
            <div class="lower-flex">
                <div><img src="//unsplash.it/600/500" alt="" class="skeleton" /></div>
                <div><img src="//unsplash.it/700/500" alt="" class="skeleton" /></div>
                <div><img src="//unsplash.it/800/500" alt="" class="skeleton" /></div>
                <div><img src="//unsplash.it/900/500" alt="" class="skeleton" /></div>
            </div>
        </div>
    </section>
  )
}

export default ProductStarting