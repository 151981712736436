import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
    baseURL: baseUrl,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
// instance.defaults.headers.common['Authorization'] = BearerToken();
export const uploadCollegeId = async (imageId) => {
    let token = localStorage.getItem("jwt");
    token = token.replace(/['"]+/g, '')
    console.log(localStorage.getItem("jwt").split('"'));
    let result = await instance.post("/collegeId/add-id", { pImages: imageId },{
        headers : {
            Authorization : "Bearer "+token
        }
    })
    return result
}

export const uploadCollegeEmailId = async(email,collegeName) => {
    let token = localStorage.getItem("jwt");
    token = token.replace(/['"]+/g, '')
    console.log(localStorage.getItem("jwt").split('"'));
    let result = await instance.post("/collegeEmailId/add-email-id",{email,collegeName},{
        headers : {
            Authorization : "Bearer "+token
        }
    })
    return result.data;
}