import React from 'react'

import './Socialmodal.css'

import youtube from '../images/youtube.png'
import twitter from '../images/twitter.png'
import discord from '../images/discord.png'
import facebook from '../images/facebook.png'
import whatsapp from '../images/whatsapp.png'
// const baseUrl = process.env.REACT_APP_API_URL
import instagram from '../images/instagram.png'

export default function SocialmodalBottom() {
  return (
    <div>
         <div className='bottomContainer'>
        <div className='Headers'>


            <div className='shareNetworks'>
                <img src={youtube} alt='#'/>
                <span>youtube</span>
            </div>

            <div className='shareNetworks'>
                <img src={twitter} alt='#'/>
                <span>Twitter</span>
            </div>

            <div className='shareNetworks'>
                <img src={whatsapp} alt='#'/>
                <span>Whatsapp</span>
            </div>

            <div className='shareNetworks'>
                <img src={discord} alt='#'/>
                <span>Discord</span>
            </div>


            <div className='shareNetworks'>
                <img src={facebook} alt='#'/>
                <span>Facebook</span>
            </div>

            <div className='shareNetworks'>
                <img src={instagram} alt='#'/>
                <span>Instagram</span>
            </div> </div>
           
            <div className='exitbutton'>
            <button>
            cancel
            </button>
            </div>

       

    </div>
    </div>
  )
}
