import React, { useEffect, useState } from 'react'
import Cards from './cards'
import download from '../../assets/testImages/download.jpeg'
import download1 from '../../assets/testImages/download1.jpeg'
import download2 from '../../assets/testImages/download2.jpeg'
import download3 from '../../assets/testImages/download3.jpeg'
import EventsModal from './eventsModal'
import './style.css'
import { GetEventByCategory } from '../../services/event'
import { createPortal } from 'react-dom';
import EventsLoader from '../../components/events-loader/EventsLoader'

export default function EventCards(props) {
  const[events, setEvents] = useState([]);
  const[eventClicked, setEventClicked] = useState([])

  const fetchEvents = async() => {
    console.log(props.category)
    const result = await GetEventByCategory(props.category);
    setEvents(result)
    console.log(result)
  }
  useEffect(() => {
    fetchEvents()
  }, [props.category])

  const [isVisible, setVisible] = useState(false)
  const handleClick = (event) => {
    setEventClicked([event])
    setVisible(true);
    props.overlay(true);
  }
  const closeModal = () => {
    setVisible(false)
    props.overlay(false);
  }
  return (
    <div className='eventCardsSection'>

      {events.length > 0 ? events.map((event) => {
        return (
          <Cards
            image={event.ePoster}
            title={event.eName}
            date={event.eDate}
            time={event.eTime}
            location={event.eLocation}
            function={() => handleClick(event)}
          />
        )
      }) 
        : <EventsLoader />
      }

      {isVisible ? createPortal(<EventsModal event={eventClicked} close={closeModal} />, document.getElementById('modal-root')) : null}

    </div>
  )
}
