import React, { useContext,useState } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../../context/ChatContext"; 
import "./chat.css";
import previous from '../../../assets/previous.png'
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getProductById } from "../../../services/product";
import { AuthContext } from '../../../context/AuthContext'
const Chat = () => {
  const [queryParams,setQueryParams] = useSearchParams();
  const [product, setProduct] = useState([
    {
      pName: "Loading",
      pPrice: "Loading",
      pImages: ["//unsplash.it/500/500"],
    },
  ]);
  async function getProduct(productId) {
    // console.log("This is ProductID",productId)
    let prod = await getProductById(productId);
    setProduct(prod);
  }
  const { data,dispatch } = useContext(ChatContext);
  const { currentUser } = useContext(AuthContext);
  // console.log("this is the data",data);
  // console.log(currentUser);
  // if(data.productId) {
  //   console.log("product id of the data : ",data.productId);
  //   getProduct(data.productId);
  // }
  // console.log("product detail",product);
  const navigate = useNavigate();
  // const location = useLocation();
  // if(location.state !== null) {
  //   const {productId} = location.state;
  //   console.log("product id :",productId);
  //   getProduct(productId);
  // }
  const OpenSidebar = function() {
    dispatch({type : "BACK_TO_SIDEBAR"});
    if(queryParams.get("openchat") === "true") {
      navigate(-1);
    }
  }

  return (
    <div className="chat">
    <div className="chatInfo">
       <span className='text-2xl'
         onClick={OpenSidebar}>
           <img className="btnPrevChat" alt="previous" src={previous} />
       </span>
       <img src="" alt="" className='h-16 w-16 object-scale-down' />
       <span className="userNameChat pl-2 text-lg">{data.user.username}</span>
     </div>
     <div className="product-Info">
        <div className="productImgName">
        <span>____</span>
        </div>
        <div className="productPrice">
          ProductPrice
        </div>        
     </div>
     <Messages/>
     <Input/>
   </div>
  )
}

export default Chat;
