// category cards component jsx file 
import React from 'react';
import LazyLoad from 'react-lazy-load';
import { Link } from "react-router-dom";
import "../categories.css";
export default function categoryCards(props) {
  return (
    <Link to={`/category/${props.Title}`} className="linkTagS" >
      <div className="categoryCardsContainerS">
        <div className="h-full">
        <LazyLoad threshold={1} onContentVisible={() => {console.log('loaded!')}}>
          <img
            className="categoryImgS"
            src={props.imgName}
            alt={props.description}
            />
          </LazyLoad>
            <p className="categoryTitleS truncate px-1">{props.Title}</p>
        </div>
      </div>
    </Link>
  );
}
