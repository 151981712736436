import React from 'react'
import youtube from '../images/youtube.png'
import './Socialmodal.css'
export default function SocialmodalTop() {
  return (
    <div>
    <div className='boxContainer'>
        <div className='image'>
            <img src={youtube} 
            // {product.pImages[0] ? `${baseUrl}/photo/get-photo/${image}` : "//unsplash.it/500/500"} alt="p1"
            
            alt='#'/>
        </div>
        <div className='imagedesc'>
        {/* {product.pName ? product.pName : "Loading"} */}
            Hiii how are youuuuu
        </div>

  
</div></div>
  )
}
