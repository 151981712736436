import React from 'react'
import "./loader.css"
const Loader = () => {
  return (
    <section className='center-loader'>
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </section>
  )
}

export default Loader