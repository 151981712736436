import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';

const ProtectedRoute = ({ children }) => {
    const auth = isAuthenticated();
    return auth ? children : <Navigate to="/getstarted" />;
}

export default ProtectedRoute;
