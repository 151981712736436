import React, { useState } from "react";
import Navbar from "./Navbar"
import Search from "./Search"
import Chats from "./Chats"
import './chatSidebar.css'
const Sidebar = () => {

  return (
    <div className="sidebar">
      <div className="navbarContanier">
      <Navbar/>
      </div>
      <Chats/>
    </div>
  );
};

export default Sidebar;
