import React, { useState, useEffect } from 'react'
import './GetStarted.css'
import Piggybank from '../../assets/PiggyBank.png'
import Study from '../../assets/Study.png'
import LeftCorner from '../../assets/Left-Corner.png'
import RightCorner from '../../assets/Right-Corner.png'
import GroupStudent from '../../assets/get_started_3.svg'
import { Link } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'

const GetStarted = () => {
    // Index for carousel
    const [active, setActive] = useState(0);

    const nextSlide = () => {
        setActive((active) => (active === 0 ? Images.length - 1 : active - 1));
    }
    const prevSlide = () => {
        setActive((active) => (active === Images.length - 1 ? 0 : active + 1));
    }
    
    // Images Carousel Data
    const Images = [
        GroupStudent,
        Study,
        Piggybank
    ]
    // Carousel Line 
    let autoSlideInterval = 3000; //Time interval for slides to change
    //Auto slide 
    useEffect(() => {
        const slideInterval = setInterval(prevSlide, autoSlideInterval)
        return () => clearInterval(slideInterval)
    }, [])
    // Dragable 
        const handler =useSwipeable({
            onSwipedLeft:()=>setActive((active) => (active === Images.length - 1 ? 0 : active + 1)),
            onSwipedRight: ()=>setActive((active) => (active === 0 ? Images.length - 1 : active - 1))
        }) 

    return (
        <div className='GetStarted'>
            <div className='GetStarted-Image'>
                {/* Background svg */}
                <div className='GetStarted-Image-Corner'>
                    <img src={LeftCorner} alt="" />
                    <img src={RightCorner} alt="" />
                </div>
                {/* Images for the Carousel */}
                <div {...handler} className='GetStarted-Carousel' >
                    <div className='GetStarted-Carousel-Image-Container' style={{ transform: `translateX(-${active * 100}%)` }}>
                        {Images.map((image) => {
                            return (
                                <img className='GetStarted-Carousel-Image' src={image} alt="" />
                            )
                        })}
                    </div>
                    {/* Indicatior for Carousel according to the number of images  */}
                    <div className='GetStarted-Carousel-Content'>

                        {active === 0 ?
                            <>
                                <span>Being student doesn't mean</span>
                                <span>you can't save extra,start</span>
                                <span>earning now on Sellular!</span>
                                
                            </>
                            :
                            active ===1 ?
                            <>
                                <span>Buy and Sell in your Campus</span>
                                <span> with ease</span>
                            </>
                            :
                            <>
                                <span>Saving bucks and enhancing</span>
                                <span> college life for students</span>
                            </>


                        }
                    </div>
                    <div className='GetStarted-Carousel-Indicator'>
                        {Images.map((image, i) => {
                            return (
                                <span className={`GetStarted-Carousel-Indicator-Icon ${active === i ? 'GetStarted-Carousel-Indicator-Active' : null}`} ></span>
                            )
                        })}
                    </div>
                </div>

            </div>
            <div className='GetStarted-Button'>
                <Link  to="/register">
                    Get Started
                </Link>
            </div>
        </div>
    )
}

export default GetStarted