import styles from './EventsLoader.module.css';

export default function EventsLoader() {
    return (
        <div className={styles.container}>
            <div className={styles.loader}>
                <div className={styles.item1}></div>
                <div className={styles.item2}></div>
                <div className={styles.item3}></div>
            </div>
        </div>
    )
}