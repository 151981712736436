import React, { useContext, useEffect, useState } from "react";
import Img from "../img/img.png";
import Attach from "../img/attach.png";
import send from "../../../assets/send.png";
import { AuthContext } from '../../../context/AuthContext'
import { ChatContext } from "../../../context/ChatContext";
import { getMessagesInConversation, postMessage } from "../../../services/chat";
import SDK from "../../../SDK";
// import io from 'socket.io-client'
const chatUrl = process.env.REACT_APP_CHAT_URL
// const socket = io.connect(`${chatUrl}`)
const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const { data,dispatch } = useContext(ChatContext);
  // console.log("To User data : ",data.user.members[1]);
  // console.log("1 from context" ,data.scroll);
  const handleSend = async() => {
    const {userJid} = SDK.getJid(data.user.fromUserId);
    console.log("this is the use jid ",userJid);
    const sendMessage = await SDK.sendTextMessage(userJid,text);
    console.log("send message response : ",sendMessage);
    setText("");
    data.scroll=true?data.scroll=false:data.scroll=true;
    // console.log("2 from context" ,data.scroll);
  }
  

  return (
    <div className="Send-input">
      <input
        className="inputBox"
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <div className="send">
        <img src={send} alt={"send button"} onClick={handleSend} />
      </div>
    </div>
  );
};

export default Input;
