import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
// Axios Config Instance
const instance = axios.create({
    baseURL: baseUrl,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
// Get All Categories
export async function getAllCategories() {
    const result = await instance.get("/category/all-category");
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}
