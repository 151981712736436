//Next and Previous Buttons for Carousel Navigation  
import React from 'react'

export default function ButtonsCarousel() {
    return (
        <div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <i className="fa-solid fa-chevron-right"></i>
            </button>
        </div>
    )
}
