import React, { useReducer, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./Products.css";
import ProductsMain from "./productsMain";
import ProductCard  from "../pages/Home/components/Products/Product";
import { getProductsByCategoryName } from "../services/product";
import { useLocation } from "react-router-dom";
import { CategoryContext } from "../context/CategoryContext";
import ProductPreview from "./ProductPreview";
const Products = (props,i) => {
  const { data, dispatch } = useContext(CategoryContext);
  const location = useLocation();
  const limit = 5;
  const handleScroll = () => {
    if(window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      dispatch({type: 'increment'});
    }
  }

  const collegeId = JSON.parse(localStorage.getItem("collegeId"));
  useEffect(() => {
    let a = async () => {
      let c = await getProductsByCategoryName(props.name,collegeId,data.page,limit);
      if (c) {
        dispatch({type: 'push', payload: c});
      }
    };
    a();
  }, [data.page, location]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return() => window.removeEventListener("scroll", handleScroll);
  }, [location])
  return (
    <div className='flex flex-col justify-center items-center' style={{marginBottom: '10vh'}}>
      {data.products.length > 0 ? (
        data.products.map((product) => (
          <ProductPreview
            pName={product.pName}
            pDescription={product.pDescription}
            pPrice={product.pPrice}
            pImage={product.pImages}
            link={product._id}
          />
        ))
      ) : (
        <div className="No-Products">
          <h1>No Products Here</h1>
          <img
            src="https://www.thebutlermart.com/assets/designer/themes/default/images/noresult.gif"
            alt=""
          />
        </div>
      )}

    <div style={{height:"5vh"}}></div>
    </div>
  );
};

export default Products;
