import { createContext, useReducer } from "react";

export const CategoryContext = createContext();

export const CategoryContextProvider = ({ children }) => {
    const INITIAL_STATE = { page: 1, products: [] }
    const CategoryReducer = (state,action) => {
        if(action.type === 'increment') {
            return {
              ...state,
              page: state.page + 1
            };
          }
          if(action.type === 'decrement') {
            return {
              ...state, 
              page: state.page - 1
            }
          }
          if(action.type === 'reset') {
            return {
              ...state,
              page: 1
            };
          }
          if(action.type === 'push') {
            return {
              ...state,
              products: [...state.products, ...action.payload]
            }
          }
          if(action.type === 'clear') {
            return {
              ...state,
              products: []
            }
          }
          throw Error('Unknown action.');
    };
    const [state,dispatch] = useReducer(CategoryReducer,INITIAL_STATE);

    return (
        <CategoryContext.Provider value={{ data:state, dispatch }}>
          {children}
        </CategoryContext.Provider>
    );
}