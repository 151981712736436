import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import Chat from './components/Chat'
import Footer from '../../layouts/Footer'
import { ChatContext } from '../../context/ChatContext'
import { useSearchParams } from 'react-router-dom'
import SDK from '../../SDK'
const ChatPage = () => {
  const {data,dispatch} = useContext(ChatContext); 
  async function openSellerChat() {
    const recentChats = await SDK.getRecentChats();
    dispatch({type : "CHANGE_USER", payload : recentChats.data[recentChats.data.length-1]});
  }
  const [queryParams,setQueryParams] = useSearchParams();
  useEffect(() => {
    function closeChat(){
      if(window.location.pathname!="/chat"||window.location.pathname!="/chat/?openchat=true"){
        dispatch({type:"BACK_TO_SIDEBAR"});
      }
    }

    return () => {
      closeChat();
    }
  },[window.location.pathname,queryParams])
  // console.log(window.location.pathname);
  // console.log("Qery Params:",queryParams.get());
  // console.log("Query Params get chat",queryParams.get("openchat"));
  if(queryParams.get("openchat") === "true") {
    openSellerChat();
  }
  return (
    <div className='home' style={{width: "100vw",height:"100vh"}}>
      <div className="chatContainer" style={{background: 'white'}}>
        {!data.chatVisibility && (<Sidebar />)}
        {data.chatVisibility && <Chat />}
      </div>
      <Footer/>
      </div>
  )
}

export default ChatPage;