import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
const instance = axios.create({
  baseURL: baseUrl,
  transformResponse: [function (data) {
    let d = JSON.parse(data);
    return d;
  }]
});
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt"))
    : false;
// instance.defaults.headers.common['Authorization'] = BearerToken();
// axios.defaults.headers.common['Authorization'] = BearerToken();
// Simple return function
function returnFunction(result) {
  if (result.data) {
    return result.data
  } else {
    console.log(result)
  }
}
export async function uploadImages(image) {
  const bodyFormData = new FormData();
  for (let i = 0; i < image.length; i++) {
    bodyFormData.append('images', image[i]);
  }
  let result = await instance.post("/photo/add-photo", bodyFormData,{
    headers : {
      Authorization : "Bearer "+BearerToken()
    }
  });
  console.log(result)
  let imageIds, personId;

  if (result.data) {
    imageIds = result.data['Files Object Id'].split(',').slice(0, 3);
    personId = await result.data["Person Id"];
  }
  return { imageIds, personId };
}
export async function getSliderImages() {
  const result = await instance.get("/customize/get-slide-image");
  return returnFunction(result);
}
// Api Url for image : /api/photo/get-photo/:imageId