import React, { useState } from 'react'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import './Mobilefooter.css'
import Home from '../assets/account_outline.svg'
export default function Footer() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
    const updateMedia = () => {
        setDesktop(window.innerWidth > 801);
    };
    const Menus = [
        { name: "Home", icon: "fa-solid fa-house", dis: "/", i: 0, tra: "translatet-x-0" },
        { name: "Chat", icon: "fa-solid fa-comment", dis: "/chat", i: 1, tra: "translatet-x-16" },
        // { name: "Products", icon: "fa-solid fa-cubes", dis: "/products", i: 2, tra: "translatet-x-32" },
        { name: "Products", icon: "fa-solid fa-cubes", dis: "/category/Calculators", i: 2, tra: "translatet-x-32" },
        { name: "Event", icon: "fa-solid fa-calendar", dis: "/events", i: 3, tra: "translatet-x-48" },
        { name: "Account", icon: "fa-solid fa-address-card", dis: "/account", i: 4, tra: "translatet-x-64" }
    ]
    const [active, setActive] = useState(0);
    const current = window.location.pathname;
    useEffect(() => {
        if (current === "/") {
            setActive(0);
        }
        else if (current === "/chat"|| current === "/chat/") {
            setActive(1);
        }
        else if (current.match("/category/*")) {
            setActive(2);
        }
        else if (current === "/events") {
            setActive(3);
        }
        else if (current === "/account") {
            setActive(4);
        }
        else {
            setActive(false)
        }

    })
    return (
        <>
            {isDesktop ? null :
                <div className='bottomNavigationContanier'>
                    {/* <div className='h-12 bg-black text-white mb-2 flex justify-center items-center'>Ad Space</div> */}
                    <ul className='bottomNavigation'>
                        {
                            Menus.map((menu, i) => (
                                <li key={i}>
                                    <NavLink onClick={() => setActive(i)} exact to={menu.dis} className='bottomNavigationLink' >
                                        <span className={`bottomNavigationIcon ${active===i && 'ActiveIcon'}`}>
                                            <i className={menu.icon}></i>
                                        </span>
                                        <span className={` ${active===i ?'activeBackground':"bottomNavigationBackground" }`}></span>
                                        {/* <span className={`bottomNavigationName ${active===i && 'ActiveName'}` }>{menu.name}</span> */}
                                    </NavLink>
                                </li>
                            ))
                        }
                        {/* <span className={` ${active===3 ? 'bottomNavigationBackgroundTeal':'bottomNavigationBackground'} ${Menus[active].tra}`}>
                        </span> */}
                    </ul>
                </div>}
        </>

    )
}

