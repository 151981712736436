import React from 'react'
import Image from '../../assets/boy_1.svg'
import WhatsApp from '../../assets/WhatsApp.png'
import User from '../../assets/login.png'
import { Link } from 'react-router-dom'
const WaLogin = () => {
    return (
        <div className='WaLogin'>
            <div className='WaLogin-Image'>
                <div className='WaLogin-Image-background'>
                    <div className='WaLogin-Image-background-Teal'></div>
                <img src={Image} alt="" />
                </div>
            </div>
            <div className='WaLogin-Content'>
                <div className='WaLogin-Content-Heading'>
                    <span>Get Set Sellular</span>
                </div>
                {/* <div className='WaLogin-Content-Desc'>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores obcaecati et voluptatem nemo? Sunt possimus quod veniam aliquid debitis optio. Itaque, tenetur distinctio? Aspernatur saepe nesciunt culpa, autem corporis quam.</p>
                </div> */}
            </div>
            <div className='WaLogin-Button-Section'>
                {/* <button onClick={()=>window.open('https://sellular.authlink.me', '_self')}><span><img src={WhatsApp} alt="" /></span><p>Login With WhatsApp</p></button> */}
                <Link className='LoginBtn' to="/loginSignUp"><span><img src={User} alt="" /></span><p>Login With E-Mail</p></Link>
            </div>
        </div>
    )
}

export default WaLogin