//Images are Imported here for carousel  Images
import React,{useEffect,useState} from 'react'
import { getSliderImages } from '../../services/images'
const baseUrl = process.env.REACT_APP_API_URL
export default function ImagesCarousel() {
    const [images, setImages] = useState(["//unsplash.it/500/500"])
  useEffect(() => {
    let a = async () => {
      let result = await getSliderImages();
      setImages(result.Images);
    }
    a();
}, [])
    return (
        <div>
            <div className="carousel-inner img-fluid">

                <div className="carousel-item active">
                    <img src="https://images.unsplash.com/photo-1561037404-61cd46aa615b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80" className="d-block w-100" alt="..." />
                </div>
                {images.map(image => {
                    return (
                        <div className="carousel-item img-fluid">
                            <img src={`${baseUrl}/photo/get-photo/${image.slideImage}`} className="d-block w-100" alt="..." />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
