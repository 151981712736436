// All the products are listed here in a row

import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ProductCard from "./Product.js";
// this is the css file which is being applied for this complete document
import "./productCards.css";
// import { AuthContext } from "../../../../context/AuthContext.js";
import { getAllProducts } from "../../../../services/product";
import swal from "sweetalert";

export default function ProductList(props) {
  const collegeId = JSON.parse(localStorage.getItem("collegeId"));
  const [products, setProducts] = useState([
    { pName: "Loading", pPrice: "Loading", pImage: ["//unsplash.it/500/500"] },
  ]);
  const [page,setPage] = useState(1);
  useEffect(() => {
    let a = async () => {
      let b = await getAllProducts(collegeId,page,3);
      if(b.Products.length === 0){
        swal({text : "No More Products!", icon : "error"})
        setPage(page-1);
        return;
      }
      console.log("page value : ",page);
      setProducts(b.Products);
      console.log(b);
    };
    a();
  },[page]);
  const [isActive, setIsActive] = useState(false);
  const Animate = () => {
    setIsActive((current) => !current);
  };

  return (
    <>
      <div className="main-container">
        {/* this div contains the heading of the product display section  */}

        <div className="section-heading text-center py-3">
          <h1 style={{ fontSize: "30px" }}>Buy for all your needs</h1>
        </div>
        {/* Back and Front button */}
        <div className="BtnHomeProductsContiner">
          <span className="BtnHomeProducts backward" onClick={() => {
            if(page > 1){
              setPage(page-1);
            }
          }}><i className="fa-solid fa-angle-left"></i></span>
          <span className="BtnHomeProducts forward" onClick={() => setPage(page+1)}><i className="fa-solid fa-angle-right"></i> </span>
        </div>
        {/* this div contians all the product cards and renders them */}

        <div className="cardsContainerHolder" onClick={Animate}>
          {/* this is the map function which maps all the product coming from backend and  */}

          {products.map((product) => {
            return (
              <ProductCard
                className="productRenderingCard"
                pName={product.pName}
                pDescription={product.pDescription}
                pPrice={product.pPrice}
                pImage={product.pImages}
                link={product._id}
                pMrp={product.pMrp}
              />
            );
          })}
        </div>
        <div className="desktopPadding"></div>
      </div>
    </>
  );
}
