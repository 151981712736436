import React from "react";
import jsstu from "../images/jssstu.jpg";
import vvce from "../images/vvce.jpg";
import gsss from "../images/gsss.jpg";
import nie from "../images/nie.jpg";
import mit from "../images/mit.jpg";
import "./modal.css";
import CollegeCard from "./collegeModalCard";

export default function CollegesModal() {
  return (
    <>
      <div className="collegesCardsContainerS">
        <CollegeCard collegeImage={nie} collegeName="NIE" />
        <CollegeCard collegeImage={nie} collegeName="NIE" />
        <CollegeCard collegeImage={vvce} collegeName="VVCE" />
        <CollegeCard collegeImage={vvce} collegeName="VVCE" />
        <CollegeCard collegeImage={jsstu} collegeName="JSSTU" />
        <CollegeCard collegeImage={jsstu} collegeName="JSSTU" />
        <CollegeCard collegeImage={mit} collegeName="MIT" />
        <CollegeCard collegeImage={mit} collegeName="MIT" />
        <CollegeCard collegeImage={gsss} collegeName="GSSS" />
        <CollegeCard collegeImage={gsss} collegeName="GSSS" />
      </div>
    </>
  );
}