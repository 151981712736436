import React, { useState, useEffect, useContext } from 'react'
import { getAllCategories } from '../../services/category';
// import {ProductContext} from '../../context/ProductContext'
import { createProduct, productByUser } from '../../services/product';
import { uploadImages } from '../../services/images';
import { AuthContext } from '../../context/AuthContext';
import MultiImageInput from 'react-multiple-image-input';
import swal from 'sweetalert';
import cross from '../../assets/cross.png'
import add from '../../assets/add.png'
import './AddProduct.css'
export default function AddProduct(props) {
    const { currentUser } = useContext(AuthContext);
    let collegeId = currentUser.collegeId;
    // GET ALL CATEGORIES
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        let a = async () => {
            let c = await getAllCategories();
            if (c) {
                setCategories(c.Categories)
            }
        };
        a();
    }, []);
    // Multiple Image
    const [images,setImages]=useState({});
        const crop = {
            unit: '%',
            aspect: 1 / 1,
            width: '100'
        };
    // DATA
    const [data, setData] = useState({ name: "", desc: "", category: "", price: "", mrp: "", file: null });
    // const [userProd, setUsProd] = useState([])
    useEffect(() => {
        let a = async () => {
            let res = await getAllCategories();
            setCategories(res.Categories);
        }
        a();
    }, [])
    // Preview
    const [preview, setPreview] = useState(false);
    // Loading
    const [loading, setLoading] = useState(false)
    // Add Product Error

    const handleAddProduct = async () => {
        console.log(data);
        if (!data.name) {
            return swal({ text: "Please Specify a Name", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (!data.desc) {
            return swal({ text: "Please Specify the Description", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (!data.category) {
            return swal({ text: "Please Choose a Category", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (!data.price) {
            return swal({ text: "Please Specify a Price", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (!data.mrp) {
            return swal({ text: "Please Specify a MRP", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (data.mrp < data.price) {
            return swal({ text: "Selling Price Must Be Less than MRP", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        if (!images) {
            return swal({ text: "Please Add A Photo Of the Product", icon: "error" }).then((willDelete) => {
                if (willDelete) {
                    setLoading(false);

                }
            })
        }
        console.log("passed all necessary checks");
        if (data.category && images && data.name && data.price && data.desc) {
            console.log(typeof(images));
            var ab,ia;
            let uploadBlob=[];
            let a;
            var arr=Object.values(images);
            console.log(arr);
            for(var i=0;i<arr.length;i++){
                console.log(typeof(arr[i]));
                var byteString = atob(arr[i].split(",")[1]);
                // separate out the mime component
                var mimeString = arr[i].split(',')[0].split(':')[1].split(';')[0];
                // write the bytes of the string to an ArrayBuffer
                ab = new ArrayBuffer(byteString.length);
                ia = new Uint8Array(ab);
                for (var j = 0; j < byteString.length; j++) {
                  ia[j] = byteString.charCodeAt(j);
                }
                //Creating array of files to upload in the array
                uploadBlob[i] = new File([ab], "test"+i+"." + mimeString.split("/")[1], { type: mimeString });
                // console.log(uploadBlob)
            }
            if (uploadBlob) {
              console.log("Uploading BLOB")
               a = await uploadImages(uploadBlob)
            //   console.log("Uploaded BLOB")
            //   console.log(a)
            }
            console.log("Images Lengt", a.imageIds.length);
            console.log("uploaded");
            function checkEmpty(arr) {
                if (arr.length > 0) return arr
            }
            let imageIds = a.imageIds.filter(checkEmpty)
            let res2 = await createProduct({ pName: data.name, pDescription: data.desc, pImage: imageIds, pCategory: data.category, pPrice: data.price, pMrp: data.mrp, personId: a.personId, pCollegeId: collegeId, creatorName: currentUser.name });
            if (res2.success) {
                setLoading(false)
                swal({ text: "Product Added Successfully" })
                window.location.reload(true)
            } else {
                setLoading(false);
                swal({ text: "Some Error Occured In Creating The product please try again !", icon: "error" })
            }
            console.log(res2);
           // var fd = new FormData();
     
            // files=await dataURItoBlob(objStore);
            // console.log(files);
            // console.log("uploading images");
            // let res = await uploadImages(images);
            // console.log(atob.imageIds);
            // console.log(images)
        }
    }
    // let res=uploadImages(data.file);
    // console.log(images);
    //Handeling OLD/NEW Click
    const handleClickOld = () => {
        document.querySelector(".old").click();
    }
    const handleClickNew = () => {
        document.querySelector(".new").click();
    }
    // Closing Modal
    const closeModal = () => {
        // window.location.reload(true);
        props.closeModal(false);
    }
    return (
        <div className='AddProduct-Modal-container'>
            {/* <div className='AddProduct-Modal-Close' onClick={closeModal}>
                <span>
                    <img src={cross} alt="" />
                </span>
            </div> */}
            <div className='AddProduct-heading'>
                <span>
                    {props.heading}
                </span>
            </div>
            <div className='AddProduct-form'>
                <form action="">
                    <div className='addProduct-input-container'>
                        <input type='text' className='addProduct-input' autoComplete="off" required="required" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                        <label className='addProduct-Lable'>Product Name</label>
                    </div>
                    <div className='Addproduct-input-container'>
                        <textarea type="text" value={data.desc} maxLength="120" onChange={(e) => {
                            setData({ ...data, desc: e.target.value })
                        }} />
                        <label htmlFor="" className="addProduct-Desc-Lable">Description</label>
                        <span className='counterWord'>{data.desc.length}/120</span>
                    </div>
                    <div className='Addproduct-input-container'>
                        <label className='condditionLable' >Condition:</label>
                        <div className="Addproduct-input-new">
                            <input className="Addproduct-input-new new" type="radio" name="inlineRadioOptions" id="NEW" />
                            <label className="form-check-label" htmlFor="inlineRadio1" onClick={handleClickNew}>NEW</label>
                        </div>
                        <div className="Addproduct-input-old">
                            <input className="Addproduct-input-old-btn old" type="radio" name="inlineRadioOptions" id="OLD" />
                            <label className="form-check-label" htmlFor="inlineRadio2" onClick={handleClickOld}>OLD</label>
                        </div>
                    </div>
                    <div className='Addproduct-input-container categorycontainer'>
                        <label className='categoryLable' >
                            Categories
                        </label>
                        <select id="countries" className="" onChange={(e) => data.category = e.target.value}>
                            <option disabled selected>Select a category</option>
                            {categories.length > 0 ? categories.map(category => {
                                return (
                                    <option value={category._id}>{category.cName}</option>
                                )
                            }) : <></>}
                        </select>
                    </div>
                    <div className='addProduct-input-container'>
                        <input className='addProduct-input' autoComplete="off" required="required" type="number" value={data.price} onChange={(e) => setData({ ...data, price: e.target.value })} />
                        <label className='addProduct-Lable'>Selling Price</label>
                    </div>
                    <div className='addProduct-input-container'>
                        <input className='addProduct-input' autoComplete="off" required="required" type="number" value={data.mrp} onChange={(e) => setData({ ...data, mrp: e.target.value })} />
                        <label className='addProduct-Lable'>Product MRP</label>
                    </div>
                    <MultiImageInput
                        images={images}
                        setImages={setImages}
                        theme="light"
                        cropConfig={{ crop, ruleOfThirds: true }}
                    />
                    {/* <div className='Addproduct-input-container' onClick={() => document.querySelector(".input-Image1").click()}>
                        <label htmlFor="">
                            <img src={add} alt="" />
                        </label>
                        <input className='input-Image1' type="file" accept="image/*" onChange={(e) => { { setData({ ...data, file: e.target.files }); console.log(e.target.value) }; setPreview(true) }} multiple hidden />
                    </div>
                    {
                        preview ?
                            <div className='flex w-full justify-around my-3'>
                                {data.file[0] ? <div className='previewImageContainer'><span onClick={() => { delete data.file[0]; console.log(data.file); setPreview(false) }}><i className="fa-solid fa-trash text-danger"></i><img src="" alt="" /></span><img src={window.URL.createObjectURL(data.file[0])} className='h-20 w-20 px-1 object-contain' /> </div> : <div className='previewImage'></div>}
                                {data.file[1] ? <div className='previewImageContainer'><span onClick={() => { { setData({ ...data, file: null }) }; setPreview(false) }}><i className="fa-solid fa-trash text-danger"></i><img src="" alt="" /></span><img src="" alt="" /><img src={window.URL.createObjectURL(data.file[1])} className='h-20 w-20 px-1 object-contain' /> </div> : <div className='previewImage'></div>}
                                {data.file[2] ? <div className='previewImageContainer'><span onClick={() => { { setData({ ...data, file: null }) }; setPreview(false) }}><i className="fa-solid fa-trash text-danger"></i><img src="" alt="" /></span><img src="" alt="" /><img src={window.URL.createObjectURL(data.file[2])} className='h-20 w-20 px-1 object-contain' /> </div> : <div className='previewImage'></div>}
                            </div>
                            :
                            <div className='flex justify-around w-full my-3'>
                                <div className='previewImage'></div>
                                <div className='previewImage'></div>
                                <div className='previewImage'></div>
                            </div>
                    } */}
                </form>

                <div className='Addproduct-submit'>

                    {loading ? (
                        <button
                            className="btn btn-primary"
                            style={{ background: "teal" }}
                            type="button"
                            disabled
                        >
                            <span
                                className="spinner-border spinner-border-sm bg-teal"
                                role="status"
                                aria-hidden="true"
                            ></span>{" "}
                            Loading...
                        </button>
                    ) : (
                        <>

                            <div
                                onClick={(e) => handleAddProduct() && setLoading(true)}
                                className="Addproduct-submit-btn"
                            >
                                {props.submitButton}
                            </div>
                            <div onClick={closeModal} className="closeModal">Cancel</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
