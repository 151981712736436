import React from 'react'

const StartingAt = () => {
  return (
    <section className='ProductSections'>
        <div class="grid">
            <div><img src="//unsplash.it/500/500" alt="" class="skeleton" /> Top Picks Under ₹199</div>
            <div><img src="//unsplash.it/600/500" alt="" class="skeleton" /> Top Picks Under ₹299</div>
            <div><img src="//unsplash.it/700/500" alt="" class="skeleton" /> Top Picks Under ₹399</div>
            <div><img src="//unsplash.it/1000/500" alt="" class="skeleton" /> Top Picks Under ₹499</div>
        </div>
    </section>
  )
}

export default StartingAt