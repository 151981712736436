import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL
// Bearer Token
const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
// Axios Config Instance
const instance = axios.create({
    baseURL: baseUrl,
    // timeout: 1000,
    transformResponse: [function (data) {
        let d = JSON.parse(data);
        return d;
    }]
});
instance.defaults.headers.common['Authorization'] = BearerToken();

// Simple return function


function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}
// Get All Events

export default async function getAllEvents() {
    const result = await instance.get("/events/all-events");
    return returnFunction(result);
}

// Get event by category

export async function GetEventByCategory(category) {
    const result = await instance.post("/events/event-by-category", {
        category: category
    });
    return result.data;
}