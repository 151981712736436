//Buttons in the carousel used switch images 


import React,{useState,useEffect} from 'react'
import { getSliderImages } from '../../services/images'
const baseUrl = process.env.REACT_APP_API_URL


export default function Indicators() {
    const [images, setImages] = useState(["//unsplash.it/500/500"])
    useEffect(() => {
        let a = async () => {
            let result = await getSliderImages();
            setImages(result.Images);
        }
        a();
    }, [])
    return (
        <div>
            <div className="carousel-indicators " id='carouselIndicators'>

                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active btnCarousle" aria-current="true" aria-label="Slide 1"></button>
                {images.map((image, i) => {
                    return (
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i + 1} className=" btnCarousle" aria-label={`Slide ${i + 1}`}></button>
                    )
                })}
            </div>
        </div>
    )
}
