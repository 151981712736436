import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Button from "./button";
import VerificationField from "./VerificationFields";
import SelectFields from "./SelectFields";
import "./Waitlist.css";
// import imagesvg from '../../../images/wave1.svg'
import CollegeImg from '../../../assets/college.png'
import VerificationModal from '../../../pages/Account/VerificationModal'
import { signUp } from "../../../services/auth";
export default function CollegeDetails({data}) {
  const india = require("indian-states-cities-list"); // package used to get the names of all indian states
  const collegeList = require("./colleges"); // package containing the names of all the colleges where sellular is present

  const [state, setState] = useState(window.sessionStorage.getItem("state")?window.sessionStorage.getItem("state"): "" ); // defines the state parameter
  const [city, setCity] = useState(window.sessionStorage.getItem("city")?window.sessionStorage.getItem("city"):""); // defines the city parameter
  const [college, setCollege] = useState(window.sessionStorage.getItem("college") ? window.sessionStorage.getItem("college"):""); // defines the college parameter

  const [isVisible, setVisible] = useState(false); // sets the visibility of the Add sellular button
  const [verification, setVerification] = useState(false); // sets the visibility of the Verification fields
  const [verifyVisibility, setVerifyVisibility] = useState(true); // sets the visibility of the verify button
  const [addCollege, setAddCollege] = useState(false);
  // console.log(data);
      const signup = async () => {
        const signupResponse = await signUp(data.name,data.email,data.password,data.cPassword,data.dob,state,city,college);
        console.log("this is the signup response :",signupResponse)
        if(signupResponse.error){
            await swal({text:signupResponse.error.email || signupResponse.error.name || signupResponse.error.password,icon:"error"})
        } else if (signupResponse.token){
            await swal({text:"Congrats! You are now a part of Sellular. Please check your mail and log in"})
            console.log(signupResponse.inWaitlist);
            if(signupResponse.inWaitlist === "true") window.location.href="/waitlist";
            else window.location.href="/register";
        }
    }
  useEffect( () => {
    // if(state){
    //   window.sessionStorage.setItem("state",state)
    //   window.sessionStorage.removeItem("city",Date.now())
    //   setCity("")
    //   console.log("setting state")
    // }
    // if(city){
    //   window.sessionStorage.setItem("city",city)
    //   window.sessionStorage.removeItem("college","")
    // }
    // if(college){
    //   window.sessionStorage.setItem("college",college)
    // }
    window.sessionStorage.setItem("state",state)
    window.sessionStorage.setItem("city",city)
    window.sessionStorage.setItem("college",college)

    setState(state);
    setCity(city);
    setCollege(college);
    console.log("Current City and State : ",state,city)
  }, [state, city, college]);

  // sets the value of state parameter

  const handleState = (e) => {
    if (e === null) {
      var state_name = document.getElementById("state");
      state_name.placeholder = "";
      setState(undefined);
      handleCity(null);
      handleCollege(null);
    } else {
      setState(e.name);
    }
  };

  // sets the value of city parameter

  const handleCity = (e) => {
    if (e === null) {
      var city_name = document.getElementById("city");
      city_name.placeholder = "";
      setCity(undefined);
      handleCollege(null);
    } else {
      setCity(e.value);
    }
  };

  // sets the value of college parameter

  const handleCollege = (e) => {
    if (e === null) {
      var college_name = document.getElementById("college");
      college_name.placeholder = "";
      setCollege(undefined);
    } else {
      setCollege(e.value);
    }
  };

  // function to maintain the wait list

  const collegeWaitlist = (e) => {
    if (city === undefined || city === null) {
      swal({
        title: "Select a city first!",
        icon: "warning",
      });
    } else {
      swal({
        title:
          "Seems we're not yet associated with your college😥 Want Sellular in your College click on Add Sellular button",
        icon: "info",
        button: "Add",
      }).then((Add) => {
        setVisible(true); // making the add sellular to your college button visible
        setVerifyVisibility(false); // making the verify button invisible
        setAddCollege(true);  // Option to add sellular to our college
      });
    }
  };

  const handleVerifyClick = () => {
    if (college === undefined || college === null) {
      swal({ title: "Enter college name first!", icon: "warning" });
      return;
    }
    setVerifyVisibility(false); // making the verify button invisible
    setVerification(true); // making the verification fields visible
    
  };

  //  custom css for the select tag used from the package react-select

  const customStyles = {
    control: (styles) => ({
      ...styles,
      fontWeight: "300",
      fontSize: "medium",
      backgroundColor: "#fff",
      borderRadius: "10px",
    }),
    option: (styles,{  isFocused}) => ({
      ...styles,
      color: "black",
      fontSize: "large",
      fontWeight: "300",
      backgroundColor:isFocused ? "#a7d9e3": null,
    }),
   
  };                                                       
  return (
    <div style={{width:'100vw',height:'100vh',display:'flex',flexDirection:'column',alignItems:'center',overflow:'hidden'}}>
      
      <div className="CollegeHeading">
        <span>College Details</span>
      </div>
      <div>
        <img src={CollegeImg}/>
      </div>
      Selected : {state} {city} { college}
      <form className="form-inline1">
        <div className="main-fields-container-waitlist">
          <SelectFields
            className="state"
            id="state"
            optionsField={india.STATES_OBJECT}
            value="test"
            functionName={(e) => handleState(e)}
            placeholder="Bharat ke bhin bhin rajya"
            styles={customStyles}
            labelName="Enter State"
          />
          <SelectFields
            className="city"
            id="city"
            optionsField={india.STATE_WISE_CITIES[state]}
            functionName={(e) => handleCity(e)}
            messageName={(e) => "Select state first!"}
            placeholder="Kaun gaon se ho?"
            styles={customStyles}
            labelName="Enter City"
          />
          {verifyVisibility ?( <SelectFields
            className="college"
            id="college"
            optionsField={collegeList.colleges[city]}
            functionName={(e) => handleCollege(e)}
            messageName={(e) => collegeWaitlist()}
            placeholder="Ye lo khojo apna college "
            styles={customStyles}
            labelName="Enter College"
          />):( <>
            {addCollege && <input placeholder="Enter your college name" className="addCollege" />}
            </>
          )}
         

          {/*  conditional rendering of the add sellular button  */}

          {isVisible ? 
          <div className="registeruser" onClick={signup}>
            <Button title="Add sellular to your college" /> 
          </div>
            : ""}

          {/* conditional rendering of the verify button  */}

          {verifyVisibility ? (
            <div className="registeruser" onClick={signup}>
              {/* <Button title="Verify" functionName={handleVerifyClick} /> */}
              <Button title="Verify" />
            </div>
          ) : (
           ""
          )}
       <div className="image-holder">
        {/* <img className="svgimage" src={imagesvg} alt="/" /> */}
      </div> 
        </div>
      </form>
     

      {/* conditional rendering of the Verification fields        */}
{/* 
      {verification ?
        <div className="VerificationModal-Container">
      <VerificationModal/> 
        </div>
      : ""} */}
    </div>
  );
}