// this file renders the cards which has product listed on them 

import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './productCards.css';
import New from '../../../../assets/new.png'


const baseUrl = process.env.REACT_APP_API_URL

function ProductCard(props) {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
  const [mrp, setMrp] = useState(false);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 801);
  };

  useEffect((props) => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <Link to={props.link ? `/product/${props.link}` : ""}>

      {/* This is the main div which contains the product cards together  */}

      <div className="cardsContainer drop-shadow-lg" >
          {/* <img className='newProductImage' src={New} /> */}
        <div className="productImageContainer">
          <img
            className="productsImage"
            alt="product"
            src={
              props.pImage
                ? `${baseUrl}/photo/get-photo/${props.pImage[0]}`
                : "https://www.thebutlermart.com/assets/designer/themes/default/images/noresult.gif"
            }
          />
        </div>
        
        <div className='w-full bg-white' style={{height: '20vh'}}>
        <div className='productDiscription'>
          {/* <div className='productBlank'></div> */}
          <div className='productPriiceAndTitle text-center'>
            <div className="productName text-center">
              <p className='truncate text-center' >
                {props.pName ? props.pName : "Card Title"} </p>
                {/* <span className='truncate' style={{ width:'70vw',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}> 
                  {props.pDescription ? props.pDescription : "Procduct Description"}
                </span> */}
                
            </div>
        {/* <div className='underline-Products'> </div> */}

            {
              props.pMrp ?
                (
                  <div className='flex justify-content-center aling-item-base w-full items-center text-center'>
                    <div className='priceAndDiscount text-center'>
                      {/* <div className='mrpProduct text-gray-400 line-through'>
                        ₹{props.pMrp}
                      </div> */}
                      <div className='text-center text-black text-lg'>
                        ₹{props.pPrice}
                      </div>
                    </div>
                    {/* <div className='discountTag'>
                      <p>
                       {Math.round(((props.pMrp - props.pPrice) / props.pMrp) * 100)}<span> % off</span>
                      </p>
                    </div> */}
                  </div>
                ) : (
                  <div className="text-center">
                    <p className="text-center text-black p-0 m-0">
                      {props.pPrice ? "₹ " + props.pPrice : "₹???"}
                    </p>
                  </div>
                )
            }
          </div>
        </div>
        <div className='bg-white w-full'>
            <button className='text-white px-3 py-2 rounded-lg' style={{backgroundColor: '#4d97a7'}}>Know more</button>
        </div>
      </div>
      </div>
    </Link>
  );
}

export default ProductCard; 